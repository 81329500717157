import React from 'react';
import styles from './DropDown.module.scss';

interface DropDownProps {
  anchor: React.ReactNode;
  children: React.ReactNode;
}

function DropDown({ anchor, children }: DropDownProps) {
  return (
    <div className={styles.wrapper}>
      {anchor}
      <div className={styles.listContainer}>
        <ul className={styles.itemList}>{children}</ul>
      </div>
    </div>
  );
}

export default DropDown;
