import React from 'react';

import Input from '@components/Input';
import { InputProps } from './Input';

interface PasswordProps extends InputProps {}

function PasswordInput({
  name = 'password',
  label = 'Password',
  placeholder = 'Your password',
  ...otherProps
}: PasswordProps) {
  return (
    <Input
      type="password"
      name={name}
      label={label}
      placeholder={placeholder}
      inputProps={{ pattern: '.{7,}' }}
      {...otherProps}
    />
  );
}

export default PasswordInput;
