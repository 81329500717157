import React from 'react';
import classNames from 'classnames';

import styles from './Switch.module.scss';

interface SwitchProps {
  active: boolean;
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function Switch({ active, className = '', disabled = false, onClick }: SwitchProps) {
  return (
    <div
      className={classNames(active ? styles.switchActive : styles.switchInactive, { [className]: Boolean(className) })}
      onClick={disabled ? () => {} : onClick}
    ></div>
  );
}

export default Switch;
