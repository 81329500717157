import React, { Component } from 'react';
import AuthPageTemplate from '@template/Pages/AuthPageTemplate';
import { PasswordInput } from '@components/Input';
import Text from '@components/Text';
import SnackbarHelper from '@helpers/SnackbarHelper';
import AuthHelper from '@helpers/AuthHelper';
import UrlHelper from '@helpers/UrlHelper';
import { ROUTES } from '@utils/system';

interface PageState {
  token: string;
  password: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  isBusy: boolean;
  resetSuccess: boolean;
}
// TODO: handle display only if all params are received
class ResetPassword extends Component<any, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      token: '',
      password: '',
      passwordError: '',
      confirmPassword: '',
      confirmPasswordError: '',
      isBusy: false,
      resetSuccess: false
    };
  }

  componentDidMount() {
    const { search } = this.props.location;

    if (search) {
      const parsedSearch = UrlHelper.parseSearch(search);

      if (parsedSearch.token) {
        this.setState({ token: parsedSearch.token.toString() });
      } else {
        this.props.history.push(ROUTES.signIn);
      }
    } else {
      this.props.history.push(ROUTES.signIn);
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<PageState, 'password' | 'confirmPassword'>);
  };

  handleSubmit = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const { token, password } = this.state;
        const result = await AuthHelper.resetPassword(token, password);

        if (result) {
          this.setState({ isBusy: false, resetSuccess: true });
        } else {
          SnackbarHelper.showError('Failed to reset password.');

          this.setState({ isBusy: false });
        }
      } else {
        this.setState({ isBusy: false });
      }
    });
  };

  handleRedirect = () => {
    this.props.history.push(ROUTES.signIn);
  };

  checkFormErrors(): boolean {
    const { password, confirmPassword } = this.state;
    const newState: any = {};

    if (password.length === 0) {
      newState.passwordError = 'Cannot be blank';
    } else if (password.length < 7) {
      newState.passwordError = 'Cannot be shorter than 7 characters';
    }

    if (confirmPassword.length === 0) {
      newState.passwordError = 'Cannot be blank';
    } else if (confirmPassword !== password) {
      newState.passwordError = 'Passwords must match';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  get legend() {
    const { resetSuccess } = this.state;

    return <Text>{resetSuccess ? 'Your new password has been created.' : 'Reset your password.'}</Text>;
  }

  render() {
    const { password, passwordError, confirmPassword, confirmPasswordError, isBusy, resetSuccess } = this.state;

    return (
      <AuthPageTemplate
        legend={this.legend}
        submitText={resetSuccess ? 'Sign in' : 'Reset password'}
        isBusy={isBusy}
        onSubmit={resetSuccess ? this.handleRedirect : this.handleSubmit}
      >
        {resetSuccess || (
          <>
            <PasswordInput
              name="password"
              label="New Password"
              placeholder="Create a password"
              value={password}
              error={passwordError}
              onChange={this.handleChange}
            />

            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm new password"
              value={confirmPassword}
              error={confirmPasswordError}
              onChange={this.handleChange}
            />
          </>
        )}
      </AuthPageTemplate>
    );
  }
}

export default ResetPassword;
