import { Component } from 'react';
import { createPortal } from 'react-dom';

import styles from './ModalBase.module.scss';

const modalRoot = document.getElementById('modal-root');
interface ModalBaseProps {
  basic?: boolean;
  clickPassthrough?: boolean;
}
class ModalBase extends Component<ModalBaseProps> {
  wrapper: HTMLDivElement;

  constructor(props: ModalBaseProps) {
    super(props);

    this.wrapper = document.createElement('div');
  }

  componentDidMount() {
    const { basic, clickPassthrough } = this.props;

    this.wrapper.classList.add(styles.modal);

    if (typeof basic === 'undefined' || basic) {
      this.wrapper.classList.add(styles.basicModal);
    }

    if (clickPassthrough) {
      this.wrapper.classList.add(styles.clickPassthrough);
    }

    modalRoot?.appendChild(this.wrapper);
  }

  componentWillUnmount() {
    modalRoot?.removeChild(this.wrapper);
  }

  render() {
    return createPortal(this.props.children, this.wrapper);
  }
}

export default ModalBase;
