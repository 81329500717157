import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './FileUploadWrapper.module.scss';

interface FileUploadWrapperProps {
  children: React.ReactNode;
  name: string;
  className?: string;
  clearInput?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class FileUploadWrapper extends Component<FileUploadWrapperProps> {
  render() {
    const { name, children, className = '', clearInput, disabled = false, onChange } = this.props;

    return (
      <div className={classNames(styles.wrapper, { [className]: Boolean(className) })}>
        {children}
        {clearInput !== true && (
          <input
            className={styles.fileInput}
            type="file"
            accept="image/*|video/*"
            multiple
            name={name}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

export default FileUploadWrapper;
