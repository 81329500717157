import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  JoyField,
  JoyMedium,
  JoyMediumsObserver,
  JoyObjectObserver,
  JoyProperty,
  JoyStatus,
  JoyUploadHelper,
  JoyUploadSessionStatus
} from 'joy-core';
import {
  JoyCrmUserHelper,
  JoyDeal,
  JoyDealHelper,
  JoyPhotosession,
  JoyPhotosessionHelper,
  photoClientsSearch
} from 'joy-crm';

import ContentViewTemplate from '@template/Pages/ContentViewTemplate';
import ClientList from './ClientList';
import MediaContainer from './MediaContainer';
import Switch from '@components/Switch';
import Text from '@components/Text';
import { RootState } from '@utils/redux/store';
import {
  setCurrentDeal,
  setCurrentPhotosession,
  setCurrentPhotosessionMedia,
  setSelectedEmail,
  setClients
} from '@utils/redux/client/actions';
import { setUploadCount, setUploadedCount, setUploadingItems, setUploadObserver } from '@utils/redux/upload/actions';
import { ROUTES } from '@utils/system';
import SnackbarHelper from '@helpers/SnackbarHelper';
import { MediaType } from './types';
import styles from './MediaManager.module.scss';

const mapStateToProps = ({ user, upload, client }: RootState) => ({
  currentUser: user.currentUser,
  currentDeal: client.currentDeal,
  currentPhotosession: client.currentPhotosession,
  currentPhotosessionMedia: client.currentPhotosessionMedia,
  clients: client.clients,
  selectedEmail: client.selectedEmail,
  uploadState: upload
});

const mapDispatchToProps = {
  setCurrentDeal,
  setCurrentPhotosession,
  setCurrentPhotosessionMedia,
  setSelectedEmail,
  setClients,
  setUploadCount,
  setUploadedCount,
  setUploadingItems,
  setUploadObserver
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MatchParams {
  clientId?: string;
  mediaId?: string;
}

interface MediaManagerProps extends PropsFromRedux, RouteComponentProps<MatchParams> {}

interface MediaManagerState {
  loadingClients: boolean;
  loadingMedia: boolean;
  showWatermark: boolean;
  clientsPage: number;
  disableLoadMore: boolean;
}

class MediaManager extends Component<MediaManagerProps, MediaManagerState> {
  private mediumsObserver: JoyMediumsObserver | null = null;
  private dealObserver: JoyObjectObserver;
  private itemsPerPage: number = 10;

  constructor(props: MediaManagerProps) {
    super(props);

    this.state = {
      loadingClients: true,
      loadingMedia: false,
      showWatermark: false,
      clientsPage: 0,
      disableLoadMore: false
    };

    this.dealObserver = new JoyObjectObserver(async (updates) => {
      if (updates.includes(JoyField.users)) {
        const usersIds = this.props.currentDeal?.users;

        if (usersIds && usersIds.length > 1) {
          this.resetDealData(true);

          this.props.history.push(ROUTES.home);
        }
      }
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { clientId, mediaId }
      }
    } = this.props;

    if (clientId) {
      this.loadMedia(clientId, mediaId);
    }

    this.handleClientSearch('', false, true, !!clientId);

    JoyUploadHelper.getSessions([
      // JoyUploadSessionStatus.error,
      // JoyUploadSessionStatus.finished,
      // JoyUploadSessionStatus.incomplete,
      JoyUploadSessionStatus.inProgress,
      JoyUploadSessionStatus.started,
      JoyUploadSessionStatus.waitingProcessing
    ])
      .then((result) => {
        const { setUploadCount, setUploadedCount, setUploadingItems } = this.props;

        result.forEach(async (item) => {
          let deal = await JoyDealHelper.get(item.groupId);

          if (deal) {
            const { id, dealId } = deal;
            let mediums = await JoyDealHelper.getMediums(id, 0, [JoyStatus.created]);

            if (mediums) {
              const currentState = this.props.uploadState[id];

              if (currentState) {
                const { uploadObserver } = currentState;

                if (uploadObserver) {
                  uploadObserver.dispose();
                }
              }

              const newUploadObserver = new JoyMediumsObserver(id, ([items, changes]) => {
                const createdItems = items.filter(
                  (item) => item.status === JoyStatus.created && item.properties.includes(JoyProperty.photosession)
                );
                const itemsCount = createdItems.length;

                if (itemsCount) {
                  setUploadCount(dealId, itemsCount);
                  setUploadedCount(dealId, 0);

                  for (let item of createdItems) {
                    let type = item.mimeType.slice(0, 5) === 'image' ? MediaType.image : MediaType.video;
                    setUploadingItems(dealId, {
                      [item.id]: {
                        thumbnailUrl: item.thumbnailUri,
                        name: item.id,
                        type,
                        status: 'Processing...'
                      }
                    });
                  }
                }
              });

              newUploadObserver.setObservableCount(0);

              setUploadObserver(dealId, newUploadObserver);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps: MediaManagerProps) {
    const { currentDeal: prevDeal, currentPhotosession: prevPhotosession, selectedEmail: prevEmail } = prevProps;
    const { currentDeal, currentPhotosession, clients, selectedEmail, setCurrentPhotosessionMedia } = this.props;

    if (currentDeal && currentPhotosession && currentPhotosession.id !== prevPhotosession?.id) {
      if (this.mediumsObserver) {
        this.mediumsObserver.dispose();
      }

      this.mediumsObserver = new JoyMediumsObserver(
        currentDeal.id,
        ([items, changes]) => {
          this.handleCurrentUploadState(currentDeal, items);
          setCurrentPhotosessionMedia(items.filter((item) => item.status === JoyStatus.processed));
        },
        currentPhotosession.id
      );

      this.mediumsObserver.setObservableCount(0);
    }

    if (prevDeal !== currentDeal) {
      currentDeal?.addObserver(this.dealObserver);
    }

    if (!prevEmail && prevEmail !== selectedEmail) {
      const selectedIndex = clients.findIndex((item) => item.email === selectedEmail);

      if (selectedIndex === -1) {
        this.handleClientSearch('', false, true, true);
      }
    }
  }

  handleCurrentUploadState = (deal: JoyDeal, items: Array<JoyMedium>) => {
    const createdItems = items.filter(
      (item) => item.status === JoyStatus.created && item.properties.includes(JoyProperty.photosession)
    );
    const itemsCount = createdItems.length;

    if (itemsCount > 0) {
      const { setUploadCount, setUploadedCount, setUploadingItems } = this.props;

      const dealId = deal.dealId;

      setUploadCount(dealId, itemsCount);
      setUploadedCount(dealId, 0);

      for (let item of createdItems) {
        let type = item.mimeType.slice(0, 5) === 'image' ? MediaType.image : MediaType.video;
        setUploadingItems(dealId, {
          [item.id]: {
            thumbnailUrl: item.thumbnailUri,
            name: item.id,
            type,
            status: 'Processing...'
          }
        });
      }
    }
  };

  loadMedia = (dealId: string, mediaId?: string) => {
    this.setState({ loadingMedia: true }, async () => {
      try {
        const currentDeal = await JoyDealHelper.get(dealId);

        if (currentDeal) {
          const { setSelectedEmail, setCurrentDeal, setCurrentPhotosession, setCurrentPhotosessionMedia } = this.props;

          const selectedEmail = currentDeal.clientEmail;
          setSelectedEmail(selectedEmail);

          const photosessions = await JoyDealHelper.getPhotosessions(currentDeal.id);
          const currentPhotosession =
            photosessions.find((item) => item.properties.includes(JoyProperty.photosession)) || null;

          if (currentPhotosession) {
            const currentPhotosessionMedia =
              (await JoyPhotosessionHelper.getMediums(currentPhotosession.id, currentDeal.id, 0, [
                JoyStatus.processed
              ])) || [];

            setCurrentDeal(currentDeal);
            setCurrentPhotosession(currentPhotosession);
            setCurrentPhotosessionMedia(currentPhotosessionMedia);

            this.setState({ loadingMedia: false }, () => {
              if (mediaId) {
                const selectedMedia = currentPhotosessionMedia.find((item) => item.id === mediaId);

                if (!selectedMedia) {
                  let link = this.generateUrl(currentDeal?.id);

                  this.props.history.push(link);
                }
              }
            });
          }
        } else {
          throw Error('Deal not found.');
        }
      } catch (error) {
        this.props.history.push(ROUTES.home);
      }
    });
  };

  generateUrl(dealId: string | undefined) {
    if (dealId) {
      return ROUTES.homeClient.replace(':clientId', dealId);
    }

    return '';
  }

  handleClientSearch = (
    search: string = '',
    loadMore: boolean = false,
    resetCache: boolean = false,
    findEmail: boolean = false
  ) => {
    this.setState({ loadingClients: true }, async () => {
      const { clients, setClients } = this.props;
      const { clientsPage, disableLoadMore: prevDisableLoadMore } = this.state;

      let disableLoadMore = prevDisableLoadMore;

      if (resetCache) {
        disableLoadMore = false;

        try {
          await photoClientsSearch(0, 0, search);
        } catch (error) {}
      }

      let page = clientsPage;
      if (loadMore) page++;
      if (resetCache) page = 0;

      let emailIndex: number | null = null;

      try {
        let foundEmail = false;

        while (!foundEmail) {
          const { selectedEmail } = this.props;
          const [clientCount, items] = await photoClientsSearch(page * this.itemsPerPage, this.itemsPerPage, search);

          disableLoadMore = clientCount < this.itemsPerPage;

          const allClients = items.map(([client, deal, status]) => {
            return {
              name: client.firstName + ' ' + client.lastName,
              email: client.email,
              status,
              dealId: deal.id
            };
          });

          if (!findEmail) {
            if (loadMore) {
              setClients([...clients, ...allClients]);
            } else {
              setClients(allClients);
            }

            break;
          } else if (selectedEmail) {
            if (page === 0) {
              setClients(allClients);
            } else {
              setClients([...this.props.clients, ...allClients]);
            }

            emailIndex = allClients.findIndex((item) => item.email === selectedEmail);

            if (emailIndex !== -1) {
              foundEmail = true;
            } else {
              page++;
            }
          }
        }
      } catch (error) {
        if (emailIndex === -1) {
          this.resetDealData();

          this.props.history.push(ROUTES.home);
        }

        if (page === 0) {
          setClients([]);
        }

        if (error.message === 'Offset is greater than the searched array') {
          disableLoadMore = true;
        }

        page = clientsPage;
      } finally {
        this.setState({ loadingClients: false, clientsPage: page, disableLoadMore });
      }
    });
  };

  resetDealData(removeClient: boolean = false) {
    const {
      clients,
      selectedEmail,
      setClients,
      setSelectedEmail,
      setCurrentDeal,
      setCurrentPhotosession,
      setCurrentPhotosessionMedia
    } = this.props;

    if (removeClient) {
      setClients(clients.filter((item) => item.email !== selectedEmail));
      setSelectedEmail('');
    }

    setCurrentDeal(null);
    setCurrentPhotosession(null);
    setCurrentPhotosessionMedia([]);
  }

  handleClientSelect = (clientEmail: string | null, dealId: string | null) => {
    const {
      currentUser,
      clients,
      setSelectedEmail,
      setCurrentDeal,
      setCurrentPhotosession,
      setCurrentPhotosessionMedia
    } = this.props;

    setSelectedEmail(clientEmail || '');

    this.setState({ loadingMedia: true, showWatermark: false }, async () => {
      if (currentUser && clientEmail && dealId) {
        const deals = await JoyCrmUserHelper.getDeals(currentUser.id, [dealId]);

        let currentDeal: JoyDeal | null = null,
          currentPhotosession: JoyPhotosession | null = null,
          currentPhotosessionMedia: Array<JoyMedium> = [];

        if (deals && deals.length > 0) {
          currentDeal = deals[0];

          if (currentDeal) {
            const photosessions = await JoyDealHelper.getPhotosessions(currentDeal.id);
            currentPhotosession =
              photosessions.find((item) => item.properties.includes(JoyProperty.photosession)) || null;

            if (currentPhotosession) {
              currentPhotosessionMedia =
                (await JoyPhotosessionHelper.getMediums(currentPhotosession.id, currentDeal.id, 0, [
                  JoyStatus.processed
                ])) || [];
            } else {
              let client = clients.find((item) => item.email === clientEmail);

              if (client) {
                currentPhotosession = await JoyPhotosessionHelper.create(currentDeal.id, client.name, client.email);
              }
            }
          }
        } else {
          SnackbarHelper.showError('The photo session could not be found.');
        }

        setCurrentDeal(currentDeal);
        setCurrentPhotosession(currentPhotosession);
        setCurrentPhotosessionMedia(currentPhotosessionMedia);

        this.setState({ loadingMedia: false }, () => {
          let link = this.generateUrl(currentDeal?.id);

          this.props.history.push(link);
        });
      }
    });
  };

  get watermarkController() {
    const { showWatermark, loadingMedia } = this.state;

    return (
      <>
        <Switch active={showWatermark} onClick={this.handleWatermarkSwitch} disabled={loadingMedia} />

        <Text className={styles.switchWatermarkLabel} variant="body">
          {showWatermark ? 'Disable Watermark' : 'Enable Watermark'}
        </Text>
      </>
    );
  }

  handleWatermarkSwitch = () => {
    const {
      currentPhotosession,
      currentDeal,
      setCurrentPhotosession,
      setCurrentPhotosessionMedia,
      match: {
        params: { clientId }
      }
    } = this.props;

    this.setState(
      (prevState) => ({
        showWatermark: !prevState.showWatermark,
        loadingMedia: true
      }),
      async () => {
        try {
          if (currentDeal && currentPhotosession && clientId) {
            const photosessions = await JoyDealHelper.getPhotosessions(currentDeal.id);

            let newPhotosession: JoyPhotosession | null;

            if (currentPhotosession.properties.includes(JoyProperty.photosession)) {
              newPhotosession = photosessions.find((item) => item.properties.includes(JoyProperty.watermark)) || null;
            } else {
              newPhotosession =
                photosessions.find((item) => item.properties.includes(JoyProperty.photosession)) || null;
            }

            if (newPhotosession) {
              const newMedia =
                (await JoyPhotosessionHelper.getMediums(newPhotosession.id, currentDeal.id, 0, [
                  JoyStatus.processed
                ])) || [];

              setCurrentPhotosession(newPhotosession);
              setCurrentPhotosessionMedia(newMedia);

              this.setState({ loadingMedia: false });
            } else {
              throw Error('Pair photosession not found.');
            }
          }
        } catch (error) {
          this.setState((prevState) => ({ showWatermark: !prevState.showWatermark, loadingMedia: false }));
        }
      }
    );
  };

  get currentUploadingClients() {
    const { uploadState } = this.props;

    return Object.keys(uploadState).filter((deal) => Object.keys(uploadState[deal].uploadingItems || {}).length > 0);
  }

  render() {
    const {
      state: { loadingClients, loadingMedia, disableLoadMore },
      props: {
        currentDeal,
        clients,
        selectedEmail,
        match: {
          params: { mediaId }
        }
      }
    } = this;

    return (
      <ContentViewTemplate noWrapper logoExtension={'portrait'}>
        <div className={styles.wrapper}>
          <ClientList
            loading={loadingClients}
            clients={clients}
            uploadingClients={this.currentUploadingClients}
            disableLoadMore={disableLoadMore}
            selectedEmail={selectedEmail}
            onSearch={this.handleClientSearch}
            onSelect={this.handleClientSelect}
          />

          <MediaContainer
            loading={loadingMedia || loadingClients}
            selectedId={mediaId}
            selectedEmail={selectedEmail}
            modalCloseUrl={this.generateUrl(currentDeal?.id)}
            watermarkController={this.watermarkController}
            loadMedia={this.loadMedia}
          />
        </div>
      </ContentViewTemplate>
    );
  }
}

export default connector(MediaManager);
