import React from 'react';

import styles from './Error404.module.scss';
import JoyLogo from '@components/JoyLogo';
import Text from '@components/Text';
import NativeLink from '@components/NativeLink';

function Error404() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <a href={process.env.REACT_APP_JOY_LINK}>
          <JoyLogo />
        </a>

        <Text className={styles.legend}>404 - Oops, something went wrong.</Text>

        <Text variant="body" className={styles.caption}>
          The page you're looking for can't be found. <br />
          If you clicked a link to an album there is a good chance that album no longer exists. If you think this is a
          mistake, contact us at <NativeLink href="mailto:hello@joy.co">hello@joy.co</NativeLink>
        </Text>
      </div>
    </div>
  );
}

export default Error404;
