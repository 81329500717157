import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import DropDown, { DropDownAnchor, DropDownItem } from '@components/DropDown';
import FlagIcon from '@components/FlagIcon';
import { RootState } from '@utils/redux/store';
import { setUserLocation } from '@utils/redux/system/actions';

const mapStateToProps = ({ system }: RootState) => ({
  location: system.location,
  isMobile: system.isMobile
});

const mapDispatchToProps = {
  setUserLocation
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SelectorProps extends PropsFromRedux {
  onChange: (location: string) => void;
}

class LanguageSelector extends Component<SelectorProps> {
  private gbFlag: React.ReactNode;
  private usFlag: React.ReactNode;

  constructor(props: SelectorProps) {
    super(props);

    const height = props.isMobile ? 12 : 15;

    this.gbFlag = <FlagIcon type="gb" height={height} />;
    this.usFlag = <FlagIcon type="us" height={height} />;
  }

  handleSelect = () => {
    const { location, setUserLocation, onChange } = this.props;
    const newLocation = location === 'US' ? 'GB' : 'US';

    setUserLocation(newLocation);

    onChange(newLocation);
  };

  get locationOptions() {
    if (this.props.location === 'US')
      return {
        anchor: this.usFlag,
        option: this.gbFlag
      };
    else
      return {
        anchor: this.gbFlag,
        option: this.usFlag
      };
  }

  render() {
    const { anchor, option } = this.locationOptions;

    return (
      <DropDown anchor={<DropDownAnchor label={anchor} />}>
        <DropDownItem label={option} onClick={this.handleSelect} />
      </DropDown>
    );
  }
}

export default connector(LanguageSelector);
