import React, { LabelHTMLAttributes, InputHTMLAttributes, Component } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

export interface InputProps {
  type?: string;
  label?: string;
  name: string;
  value?: string | number;
  error?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

class Input extends Component<InputProps> {
  handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;

    const input = event.currentTarget;
    input.setAttribute('data-value', input.value.length > 0 ? 'true' : 'false');

    const parentNode = input.parentNode as HTMLDivElement;
    if (parentNode) parentNode.removeAttribute('data-error');

    onChange(event);
  };

  render() {
    const {
      type = 'text',
      label = '',
      name,
      value,
      error,
      defaultValue,
      disabled = false,
      placeholder,
      className = '',
      labelProps,
      inputProps
    } = this.props;

    let currentInputProps = { ...inputProps };

    if (typeof value !== 'undefined' || value !== null) {
      Object.assign(currentInputProps, { value });
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          {
            [className]: Boolean(className)
          },
          { [styles.wrapperError]: !!error }
        )}
        data-error={error}
      >
        <input
          type={type}
          name={name}
          defaultValue={defaultValue}
          data-value={defaultValue && true}
          disabled={disabled}
          placeholder={placeholder}
          className={styles.input}
          onChange={this.handleChange}
          {...currentInputProps}
        />

        <label className={styles.label} {...labelProps}>
          {label}
        </label>
      </div>
    );
  }
}

export default Input;
