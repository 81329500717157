import React from 'react';

import { ReactComponent as GBIcon } from '@assets/flags/gb-flag.svg';
import { ReactComponent as USIcon } from '@assets/flags/us-flag.svg';

interface IconType {
  type: 'gb' | 'us';
  height?: number;
}

export default function FlagIcon({ type, height = 15 }: IconType) {
  switch (type) {
    case 'gb':
      return <GBIcon width={2 * height} height={height} />;
    case 'us':
      return <USIcon width={2 * height} height={height} />;
    default:
      return null;
  }
}
