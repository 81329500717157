import React from 'react';
import classNames from 'classnames';

import styles from './LoadingLoop.module.scss';

interface LoopProps {
  color?: 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white' | 'black';
  className?: string;
}

function LoadingLoop({ color = 'default', className = '' }: LoopProps) {
  return (
    <div className={classNames(styles.wrapper, styles[`${color}Color`], { [className]: Boolean(className) })}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
}

export default LoadingLoop;
