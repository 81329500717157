import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import JoyLogo from '@components/JoyLogo';
import BountyLogo from '@components/BountyLogo';
import Text from '@components/Text';
import NativeLink from '@components/NativeLink';
import { RootState } from '@utils/redux/store';
import styles from './CheckoutResponse.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  language: system.location
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

function CheckoutResponse({ language }: PropsFromRedux) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <a href={process.env.REACT_APP_JOY_LINK}>{language === 'US' ? <JoyLogo /> : <BountyLogo />}</a>

        <Text className={styles.header}>Thanks for your order!</Text>

        <Text variant="paragraph" className={styles.paragraph}>
          Please check your email inbox for access to your purchased items. <br />
          <br />
          We hope to see you again soon.
        </Text>
        <br />
        <Text variant="paragraph" className={styles.paragraphTeam}>
          The {language === 'US' ? 'Joy' : 'Bounty'} Team.
        </Text>
        <br />
        <NativeLink href={process.env.REACT_APP_JOY_LINK || '/'}>Visit joy.co</NativeLink>
      </div>
    </div>
  );
}

export default connector(CheckoutResponse);
