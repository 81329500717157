import React from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '@utils/redux/store';
import styles from './Footer.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface FooterProps extends PropsFromRedux {
  leftColumn?: React.ReactNode;
  middleColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
}

function Footer({ leftColumn, middleColumn, rightColumn, isMobile }: FooterProps) {
  return (
    <div className={classNames(styles.wrapper, { [styles.wrapperMobile]: isMobile })}>
      <div className={styles.container}>
        <div className={classNames(styles.content, { [styles.contentMobile]: isMobile })}>
          {isMobile ? (
            <>
              <div className={styles.leftMobile}>{leftColumn}</div>
              <div className={styles.middleMobile}>{middleColumn}</div>
              <div className={styles.rightMobile}>{rightColumn}</div>
            </>
          ) : (
            <>
              <div className={styles.left}>{leftColumn}</div>
              <div className={styles.middle}>{middleColumn}</div>
              <div className={styles.right}>{rightColumn}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default connector(Footer);
