import { detect } from 'detect-browser';
import { JoyField } from 'joy-core';
import { JoyCrmIntegrationService, JoyCrmIntegrationServiceFunction } from 'joy-crm';

import store from '@utils/redux/store';
import { setBrowser, setUserLocation } from '@utils/redux/system/actions';

export const ROUTES = {
  home: '/',
  homeClient: '/client/:clientId',
  homeClientMedia: '/client/:clientId/media/:mediaId',
  session: '/client/:dealId/session/:photosessionId',
  sessionMedia: '/client/:dealId/session/:photosessionId/media/:mediaId',
  previewSession: '/client/:dealId/previewSession/:photosessionId',
  previewSessionMedia: '/client/:dealId/previewSession/:photosessionId/media/:mediaId',
  checkout: '/checkout/:dealId/session/:photosessionId',
  checkoutResponse: '/checkout/result',
  signIn: '/sign-in',
  signUp: '/sign-up',
  verify: '/verify',
  resetPassword: '/reset',
  forgotPassword: '/forgot-password',
  error404: '/404'
};

export const PACKAGE_DETAILS: Array<{ limit: number; limitMessage: string }> = [
  { limit: 5000, limitMessage: ' ' },
  { limit: 5000, limitMessage: ' ' },
  { limit: 5000, limitMessage: ' ' },
  { limit: 5000, limitMessage: ' ' },
  { limit: 1, limitMessage: ' out of maximum 1 ' },
  { limit: 3, limitMessage: ' out of maximum 3 ' },
  { limit: 5000, limitMessage: ' ' }
];

export async function systemInit() {
  const browser = detect();

  if (browser) {
    const { name, os, type, version } = browser;

    store.dispatch(setBrowser({ name, os, type, version }));
  }

  try {
    const location = await JoyCrmIntegrationService.instance.call(JoyCrmIntegrationServiceFunction.userLocation);

    store.dispatch(setUserLocation(location[JoyField.countryCode]));
  } catch (error) {
    console.log('System initialization error.');
  }
}
