import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyMedium } from 'joy-core';

import IconButton from '@components/IconButton';
import Text from '@components/Text';
import RadioCheckedIcon from '@icons/RadioCheckedIcon';
import RadioUncheckedIcon from '@icons/RadioUncheckedIcon';
import VideoCameraIcon from '@icons/VideoCameraIcon';
import { RootState } from '@utils/redux/store';

import styles from './MediaGrid.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MediaGridProps extends PropsFromRedux {
  items: Array<JoyMedium>;
  actions?: React.ReactNode;
  selectedItems?: Array<string>;
  simpleItem?: boolean;
  showSelected?: boolean;
  publicView?: boolean;
  onSelect?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  generateItemUrl: (mediaId: string | undefined) => string;
}

class MediaGrid extends Component<MediaGridProps> {
  renderItem = (item: JoyMedium, showSelected: boolean = false) => {
    const { selectedItems, simpleItem, generateItemUrl, onSelect, isMobile } = this.props;
    const isSelected = selectedItems ? selectedItems.includes(item.id) : false;
    const catchClick = showSelected ? { onClick: this.catchLinkClick } : {};
    const { r, g, b, a } = item.meanColor;

    return (
      <div
        key={`media-${item.id}`}
        className={classNames(styles.mediaItem, { [styles.mediaItemMobile]: isMobile })}
        style={{ flex: `${item.width / item.height} 0%`, backgroundColor: `rgba(${r},${g},${b},${a})` }}
      >
        {simpleItem || (
          <IconButton
            className={classNames(styles.itemSelect, { [styles.showSelected]: showSelected })}
            icon={isSelected ? RadioCheckedIcon : RadioUncheckedIcon}
            color="white"
            data-value={item.id}
            onClick={onSelect}
          />
        )}

        <Link
          className={classNames({ [styles.mediaItemSelected]: isSelected })}
          to={generateItemUrl(item.id)}
          data-value={item.id}
          {...catchClick}
        >
          <img src={item.thumbnailUri} alt={item.id} className={styles.thumbnail} draggable="false" />
        </Link>

        {item.mimeType.startsWith('video') && <VideoCameraIcon className={styles.videoMark} color="white" />}
      </div>
    );
  };

  catchLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(event);
    }
  };

  render() {
    const { items, actions, publicView, showSelected, isMobile } = this.props;

    return (
      <>
        {actions}

        {!publicView && items.length === 0 ? (
          <Text className={styles.mediaGridMessage} variant="body" color="muted">
            This photosession is empty. Please add new files.
          </Text>
        ) : isMobile ? (
          splitMediaMobile(items).map((row: Array<JoyMedium>, index: number) => (
            <div key={`mediaRow-${index}`} className={classNames(styles.row, styles.rowMobile)}>
              {row.map((item: JoyMedium) => this.renderItem(item, showSelected))}
            </div>
          ))
        ) : (
          splitMedia(items).map((row: Array<JoyMedium>, index: number) => (
            <div key={`mediaRow-${index}`} className={styles.row}>
              {row.map((item: JoyMedium) => this.renderItem(item, showSelected))}
            </div>
          ))
        )}
      </>
    );
  }
}

export default connector(MediaGrid);

/**
 * This algorithm was carried over from the old web app
 *
 * @param items
 */
function splitMedia(items: Array<JoyMedium>) {
  return items.reduce(
    (rows: Array<Array<JoyMedium>>, item: JoyMedium, index: number) => {
      const isLast = index === items.length - 1;
      const landscape = item.width > item.height;
      const { length: l, [l - 1]: last } = rows;

      switch (true) {
        case isLast:
          last.push(item);
          break;
        case last.length > 2:
        case last.length >= 2 && landscape:
          rows.push([item]);
          break;
        default:
          last.push(item);
      }

      return rows;
    },
    [[]]
  );
}

/**
 *
 * @param items
 */
function splitMediaMobile(items: Array<JoyMedium>) {
  const length = items.length;
  const newItems: Array<Array<JoyMedium>> = [];

  for (let i = 0; i < length; i += 2) {
    if (items[i + 1]) {
      newItems.push([items[i], items[i + 1]]);
    } else {
      newItems.push([items[i]]);
    }
  }

  return newItems;
}
