import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import client from '@utils/redux/client/reducers';
import upload from '@utils/redux/upload/reducers';
import user from '@utils/redux/user/reducers';
import system from '@utils/redux/system/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers({ client, upload, user, system });

let composedMiddleware = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {
  composedMiddleware = compose(
    composedMiddleware,
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : (f) => f
  );
}

export type RootState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, composedMiddleware);
