import React, { Component } from 'react';

import styles from './MessagePageTemplate.module.scss';
import JoyLogo from '@components/JoyLogo';

interface MessagePageProps {
  children?: React.ReactNode;
  legend?: React.ReactNode;
}

class MessagePageTemplate extends Component<MessagePageProps> {
  render() {
    const { children, legend } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <a href={process.env.REACT_APP_JOY_LINK}>
            <JoyLogo />
          </a>

          <div className={styles.legend}>{legend}</div>

          {children}
        </div>
      </div>
    );
  }
}

export default MessagePageTemplate;
