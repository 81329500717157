import React, { Component } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { JoyProperty, isEmail } from 'joy-core';

import AuthPageTemplate from '@template/Pages/AuthPageTemplate';
import { EmailInput, PasswordInput } from '@components/Input';
import Text from '@components/Text';
import NativeLink from '@components/NativeLink';
import AuthHelper from '@helpers/AuthHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import styles from './SignIn.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  snackbar: system.snackbar
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PageState {
  showPassword: boolean;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  isBusy: boolean;
  redirectToSignUp: boolean;
  lockEmail: boolean;
}

interface PageProps extends PropsFromRedux, RouteComponentProps {}
//TODO: handle google login
class SignIn extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.state = {
      showPassword: false,
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      isBusy: false,
      redirectToSignUp: false,
      lockEmail: false
    };
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === 'email' && this.props.snackbar !== null) {
      SnackbarHelper.hideSnackbar();
    }

    this.setState({ [name]: value, [`${name}Error`]: '' } as Pick<PageState, 'email' | 'password'>);
  };

  handleSubmit = () => {
    this.setState({ isBusy: true }, async () => {
      if (this.checkFormErrors()) {
        const { email, password } = this.state;

        if (email && password) {
          const result = await AuthHelper.signIn(email, password);

          if (result) {
            this.props.history.push(ROUTES.home);
          } else {
            // TODO: handle login errors
            this.setState({ isBusy: false, passwordError: 'Incorrect password' });
          }
        } else {
          try {
            const result = await AuthHelper.checkUserExists(email);

            if (result) {
              const { exists, isAnonymous, properties } = result;

              if (
                exists &&
                (properties.includes(JoyProperty.photographer) || properties.includes(JoyProperty.photoeditor))
              ) {
                if (isAnonymous) {
                  this.setState({ redirectToSignUp: true, lockEmail: true });
                } else {
                  this.setState({ isBusy: false, showPassword: true });
                }
              } else {
                throw Error('Invalid user');
              }
            } else {
              throw Error('Invalid user');
            }
          } catch (error) {
            SnackbarHelper.showInfo(
              <>
                Invalid account. Maybe you want to access &nbsp;
                <NativeLink href={process.env.REACT_APP_JOY_LINK || ''} className={styles.joyLink}>
                  joy.co
                </NativeLink>
              </>
            );

            this.setState({ isBusy: false, emailError: 'Invalid Account' });
          }
        }
      } else {
        this.setState({ isBusy: false });
      }
    });
  };

  checkFormErrors(): boolean {
    const { showPassword, email, password } = this.state;
    const newState: any = {};

    if (email.length === 0) {
      newState.emailError = 'Cannot be blank';
    } else if (isEmail(email) === false) {
      newState.emailError = 'Email address is not valid';
    }

    if (showPassword && password.length === 0) {
      newState.passwordError = 'Cannot be blank';
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      return false;
    }

    return true;
  }

  get legend() {
    return <Text>{this.state.showPassword ? 'Welcome back!' : 'Sign in or create an account.'}</Text>;
  }

  get cardActionsExtension() {
    if (this.state.showPassword) {
      return (
        <Link to={{ pathname: ROUTES.forgotPassword, state: { email: this.state.email } }}>
          <Text variant="body" color="muted">
            Forgot your password?
          </Text>
        </Link>
      );
    }

    return null;
  }

  render() {
    const {
      showPassword,
      email,
      emailError,
      password,
      passwordError,
      isBusy,
      redirectToSignUp,
      lockEmail
    } = this.state;

    if (redirectToSignUp) {
      return <Redirect push to={{ pathname: ROUTES.signUp, state: { email, lockEmail } }} />;
    }

    return (
      <AuthPageTemplate
        legend={this.legend}
        cardActionsExtension={this.cardActionsExtension}
        submitText={showPassword ? 'Sign in' : ' Submit'}
        isBusy={isBusy}
        onSubmit={this.handleSubmit}
      >
        <EmailInput name="email" value={email} error={emailError} onChange={this.handleChange} />

        {showPassword && (
          <PasswordInput name="password" value={password} error={passwordError} onChange={this.handleChange} />
        )}
      </AuthPageTemplate>
    );
  }
}

export default connector(SignIn);
