import React from 'react';
import classNames from 'classnames';

import styles from './Text.module.scss';

interface TextProps {
  children: React.ReactNode;
  variant?:
    | 'body'
    | 'bodySmall'
    | 'caption'
    | 'title'
    | 'titleSmall'
    | 'titleLarge'
    | 'subtitle'
    | 'paragraph'
    | 'heading';
  color?: 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white';
  className?: string;
  tag?: 'div' | 'span';
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function Text({ children, variant = 'title', color = 'default', className = '', tag, onClick }: TextProps) {
  switch (tag) {
    default:
    case 'div':
      return (
        <div
          className={classNames(styles.textCommon, styles[`${variant}Variant`], styles[`${color}Color`], {
            [styles.clickable]: !!onClick,
            [className]: Boolean(className)
          })}
          onClick={onClick}
        >
          {children}
        </div>
      );
    case 'span':
      return (
        <span
          className={classNames(styles.textCommon, styles[`${variant}Variant`], styles[`${color}Color`], {
            [styles.clickable]: !!onClick,
            [className]: Boolean(className)
          })}
          onClick={onClick}
        >
          {children}
        </span>
      );
  }
}

export default Text;
