import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ROUTES } from '@utils/system';
import styles from './DropDown.module.scss';

interface DropDownItemProps {
  label: React.ReactNode;
  component?: 'button' | 'a';
  to?: LinkProps['to'];
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

function DropDownItem({ component, to = ROUTES.home, label, onClick }: DropDownItemProps) {
  if (component === 'a') {
    return (
      <li className={styles.itemLink}>
        <Link to={to}>{label}</Link>
      </li>
    );
  }

  return (
    <li className={styles.itemButton} onClick={onClick}>
      {label}
    </li>
  );
}

export default DropDownItem;
