import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyMedium, JoyStatus, JoyMediumsObserver, JoyChangeType } from 'joy-core';
import { JoyPhotosession, JoyPhotosessionHelper } from 'joy-crm';

import ContentViewTemplate from '@template/Pages/ContentViewTemplate';
import MediaGrid from '@sections/MediaManager/MediaGrid';
import Header from '@template/Header';
import Text from '@components/Text';
import Button from '@components/Button';
import LoadingLoop from '@components/LoadingLoop';
import LanguageSelector from '@components/LanguageSelector';
import { RootState } from '@utils/redux/store';
import { ROUTES, PACKAGE_DETAILS } from '@utils/system';
import MediaModal from '../MediaModal';
import styles from './Photosession.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  userLocation: system.location,
  isMobile: system.isMobile
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MatchParams {
  photosessionId: string;
  dealId: string;
  mediaId?: string;
}

interface LinkState {
  plan?: number;
}

interface PageProps extends PropsFromRedux, RouteComponentProps<MatchParams> {}

interface PageState {
  loading: boolean;
  linkState: LinkState | null;
  selectedItems: Array<string>;
  currentDealId: string | null;
  currentPhotosession: JoyPhotosession | null;
  currentPhotosessionMedia: Array<JoyMedium>;
}

class Photosession extends Component<PageProps, PageState> {
  mediumsObserver: JoyMediumsObserver | null = null;

  constructor(props: PageProps) {
    super(props);

    this.state = {
      loading: true,
      linkState: null,
      selectedItems: [],
      currentDealId: null,
      currentPhotosession: null,
      currentPhotosessionMedia: []
    };
  }

  componentDidMount() {
    const {
      location: { state, pathname },
      history
    }: any = this.props;

    if (state) {
      this.setState({ linkState: state }, () => {
        history.replace(pathname, undefined);

        this.loadData();
      });
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps: PageProps, prevState: PageState) {
    const {
      match: {
        params: { photosessionId: prevPhotosessionId, dealId: prevDealId }
      }
    } = prevProps;
    const {
      match: {
        params: { photosessionId, dealId }
      }
    } = this.props;
    const { currentDealId, currentPhotosession } = this.state;

    if (photosessionId && dealId && (prevDealId !== dealId || prevPhotosessionId !== photosessionId)) {
      this.setMediumsObserver(dealId, photosessionId);
    }

    if (prevState.currentPhotosession === null && currentPhotosession && currentDealId) {
      this.setMediumsObserver(currentDealId, currentPhotosession.id);
    }
  }

  setMediumsObserver(dealId: string, photosessionId: string) {
    if (this.mediumsObserver) {
      this.mediumsObserver.dispose();
    }

    this.mediumsObserver = new JoyMediumsObserver(
      dealId,
      ([items, changes]) => {
        const changeIndex = changes.findIndex(
          (change) =>
            change.type === JoyChangeType.removed ||
            (change.type === JoyChangeType.added && items.length !== this.state.currentPhotosessionMedia.length)
        );

        if (changeIndex !== -1) {
          this.loadData();
        }
      },
      photosessionId
    );

    this.mediumsObserver.setObservableCount(0);
  }

  loadData = () => {
    this.setState({ loading: true }, async () => {
      const {
        match: {
          params: { photosessionId, dealId }
        }
      } = this.props;

      try {
        const currentPhotosession = await JoyPhotosessionHelper.get(photosessionId, dealId);

        if (currentPhotosession) {
          const currentPhotosessionMedia =
            (await JoyPhotosessionHelper.getMediums(
              currentPhotosession.id,
              dealId,
              0,
              [JoyStatus.processed],
              undefined,
              true
            )) || [];

          this.setState(
            { currentDealId: dealId, currentPhotosession, currentPhotosessionMedia, loading: false },
            () => {
              const { linkState } = this.state;

              if (
                linkState &&
                typeof linkState.plan !== 'undefined' &&
                linkState.plan >= 0 &&
                PACKAGE_DETAILS[linkState.plan]
              ) {
                const { limit } = PACKAGE_DETAILS[linkState.plan];

                if (limit === 5000) {
                  this.handleCheckoutRedirect();
                }
              }
            }
          );
        } else {
          throw Error('Watermark photosession not found.');
        }
      } catch (error) {
        this.props.history.push(ROUTES.error404);
      }
    });
  };

  get modalCloseUrl() {
    const {
      match: {
        params: { photosessionId, dealId }
      }
    } = this.props;

    return ROUTES.session.replace(':photosessionId', photosessionId).replace(':dealId', dealId);
  }

  generateItemUrl = (mediaId: string | undefined): string => {
    if (mediaId) {
      const {
        match: {
          params: { photosessionId, dealId }
        }
      } = this.props;

      return ROUTES.sessionMedia
        .replace(':photosessionId', photosessionId)
        .replace(':dealId', dealId)
        .replace(':mediaId', mediaId);
    }

    return '';
  };

  get mediaItemInfo() {
    const {
      state: { currentPhotosessionMedia: items },
      props: {
        match: {
          params: { mediaId }
        }
      }
    } = this;

    const itemIndex = items.findIndex((item) => item.id === mediaId);

    const itemsLength = items.length;
    let prevUrl = '';
    let nextUrl = '';

    if (itemsLength > 1) {
      prevUrl = this.generateItemUrl(items[itemIndex - 1]?.id || items[itemsLength - 1].id);
      nextUrl = this.generateItemUrl(items[itemIndex + 1]?.id || items[0].id);
    }

    return { item: items[itemIndex], prevUrl, nextUrl };
  }

  handleItemSelect = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();

    const { selectedItems } = this.state;
    const itemId = event.currentTarget.getAttribute('data-value') || '';

    if (selectedItems.includes(itemId)) {
      this.setState({ selectedItems: selectedItems.filter((item) => item !== itemId) });
    } else {
      const { linkState } = this.state;

      if (
        linkState &&
        typeof linkState.plan !== 'undefined' &&
        linkState.plan >= 0 &&
        PACKAGE_DETAILS[linkState.plan]
      ) {
        const { limit } = PACKAGE_DETAILS[linkState.plan];

        if (selectedItems.length < limit) {
          this.setState({ selectedItems: [...selectedItems, itemId] });
        }
      }
    }
  };

  handleBuyRedirect = () => {
    this.props.history.push({
      pathname: this.generateCheckoutUrl(),
      state: {
        plan: -1
      }
    });
  };

  handleCheckoutRedirect = () => {
    const { linkState, currentPhotosession, selectedItems } = this.state;

    if (linkState && typeof linkState.plan !== 'undefined') {
      const selectedPlan = PACKAGE_DETAILS[linkState.plan];

      let planDescription: string;
      if (selectedPlan.limit === 5000) {
        planDescription = `ALBUM-${currentPhotosession?.id}`;
      } else {
        planDescription = 'MEDIA';

        selectedItems.forEach((item) => {
          planDescription += `-${item}`;
        });
      }

      this.props.history.push({
        pathname: this.generateCheckoutUrl(),
        state: {
          photosessionId: currentPhotosession?.id,
          plan: linkState.plan,
          description: planDescription,
          media: selectedItems
        }
      });
    }
  };

  handleLanguageChange = () => {
    const { linkState } = this.state;

    if (linkState !== null) {
      this.props.history.push(this.generateCheckoutUrl());
    }
  };

  generateCheckoutUrl() {
    const { currentDealId, currentPhotosession } = this.state;

    if (currentDealId && currentPhotosession) {
      return ROUTES.checkout.replace(':dealId', currentDealId).replace(':photosessionId', currentPhotosession.id);
    }

    return '';
  }

  get headerContent() {
    const {
      state: { linkState, selectedItems },
      props: { isMobile }
    } = this;

    let content = (
      <>
        <LanguageSelector onChange={this.handleLanguageChange} />

        <Button
          color="secondary"
          className={isMobile ? styles.headerButtonMobile : styles.headerButton}
          onClick={this.handleBuyRedirect}
        >
          {isMobile ? 'Checkout' : 'Buy your photos'}
        </Button>
      </>
    );

    if (linkState && typeof linkState.plan !== 'undefined') {
      const { limit, limitMessage } = PACKAGE_DETAILS[linkState.plan];
      const disabledAction = limit !== 5000 && selectedItems.length === 0;

      const message = isMobile
        ? `${selectedItems.length}/${limit}`
        : `${selectedItems.length}${limitMessage}photos selected.`;

      content = (
        <>
          <Text variant="body">{message}</Text>

          <Button
            color="secondary"
            className={styles.headerButton}
            disabled={disabledAction}
            onClick={this.handleCheckoutRedirect}
          >
            Checkout
          </Button>
        </>
      );
    }

    return <Header content={content} useLocation logoExtension="portrait" fixedHeader />;
  }

  render() {
    const {
      state: { selectedItems, loading, currentPhotosession, currentPhotosessionMedia, linkState },
      props: {
        match: {
          params: { mediaId }
        },
        isMobile
      }
    } = this;

    const simpleItem = linkState === null;

    return (
      <ContentViewTemplate header={this.headerContent} fixedHeader>
        {loading ? (
          <LoadingLoop color="disabled" />
        ) : (
          <>
            <Text className={classNames(styles.clientName, { [styles.clientNameMobile]: isMobile })} variant="heading">
              {currentPhotosession?.name}
            </Text>

            <MediaGrid
              items={currentPhotosessionMedia}
              selectedItems={selectedItems}
              simpleItem={simpleItem}
              showSelected={!simpleItem}
              publicView
              onSelect={this.handleItemSelect}
              generateItemUrl={this.generateItemUrl}
            />

            <MediaModal open={!!mediaId} mediaItem={this.mediaItemInfo} modalCloseUrl={this.modalCloseUrl} />
          </>
        )}
      </ContentViewTemplate>
    );
  }
}

export default connector(Photosession);
