import React, { Component } from 'react';

import SearchIcon from '@icons/SearchIcon';
import styles from './SearchInput.module.scss';

export interface SearchInputProps {
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

class SearchInput extends Component<SearchInputProps> {
  render() {
    const { value, defaultValue, disabled = false, onChange } = this.props;

    let valueProps = { defaultValue };

    if (typeof value !== 'undefined' || value !== null) {
      Object.assign(valueProps, { value });
    }

    return (
      <div className={styles.wrapper}>
        <SearchIcon className={styles.icon} size={20} padding="md" color="disabled" />

        <input
          type="text"
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder="Search"
          className={styles.input}
          onChange={onChange}
          {...valueProps}
        />
      </div>
    );
  }
}

export default SearchInput;
