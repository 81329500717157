import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  JoyProperty,
  JoyUploadSessionType,
  JoyFieldMap,
  JoyField,
  JoyUploadService,
  JoyUploadServiceUploadType,
  JoyObjectObserver,
  JoyStatus,
  JoyUploadHelper,
  JoyMediumHelper
} from 'joy-core';
import { JoyDealHelper, JoyPhotosessionHelper, sendAlbumUrl } from 'joy-crm';

import DialogTemplate from '@template/Overlays/DialogTemplate';
import SnackbarTemplate from '@template/Overlays/SnackbarTemplate';
import FileUploadWrapper from '@components/FileUploadWrapper';
import Button from '@components/Button';
import IconButton from '@components/IconButton';
import Text from '@components/Text';
import LoadingLoop from '@components/LoadingLoop';
import UploadQueue, { UploadItemProps } from '@components/UploadQueue';
import CloseIcon from '@icons/CloseIcon';
import UrlHelper from '@helpers/UrlHelper';
import SnackbarHelper from '@helpers/SnackbarHelper';
import AuthHelper from '@helpers/AuthHelper';
import { RootState } from '@utils/redux/store';
import {
  setUploadCount,
  setUploadedCount,
  addToUploadedCount,
  setUploadingItems,
  removeUploadingItem,
  setUploadingTasks,
  removeUploadingTask,
  setUploadSession
} from '@utils/redux/upload/actions';
import { UploadState } from '@utils/redux/upload/reducers';
import { ROUTES } from '@utils/system';
import MediaModal from '../MediaModal';
import MediaGrid from '../MediaGrid';
import { MediaType } from '../types';
import styles from './MediaContainer.module.scss';

const mapStateToProps = ({ client, upload, user }: RootState) => ({
  currentDeal: client.currentDeal,
  currentPhotosession: client.currentPhotosession,
  currentPhotosessionMedia: client.currentPhotosessionMedia,
  uploadState: upload,
  currentUser: user.currentUser
});

const mapDispatchToProps = {
  setUploadCount,
  setUploadedCount,
  addToUploadedCount,
  setUploadingItems,
  removeUploadingItem,
  setUploadingTasks,
  removeUploadingTask,
  setUploadSession
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PageProps extends PropsFromRedux {
  loading: boolean;
  selectedId?: string;
  selectedEmail?: string;
  modalCloseUrl: string;
  watermarkController?: React.ReactNode;
  loadMedia: (dealId: string, mediaId?: string) => void;
}

interface PageState {
  selectedItems: Array<string>;
  openDeleteDialog: boolean;
  deleteAction: 'all' | 'selected';
  showQueueWidget: boolean;
  showLoading: boolean;
  shareLoading: boolean;
}

//TODO: implement isMobile
class MediaContainer extends Component<PageProps, PageState> {
  private isUserPhotoeditor: boolean = false;

  constructor(props: PageProps) {
    super(props);

    this.state = {
      selectedItems: [],
      openDeleteDialog: false,
      deleteAction: 'selected',
      showQueueWidget: false,
      showLoading: false,
      shareLoading: false
    };

    this.isUserPhotoeditor = AuthHelper.userHasRole(JoyProperty.photoeditor);
  }

  handleAddMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentDeal,
      currentPhotosession,
      currentUser,
      uploadState,
      setUploadCount,
      setUploadedCount,
      addToUploadedCount,
      setUploadingItems,
      setUploadingTasks,
      removeUploadingItem,
      removeUploadingTask,
      setUploadSession
    } = this.props;
    const files = event.currentTarget.files;

    if (currentDeal && currentPhotosession && files) {
      const currentDealId = currentDeal.id;
      const acDealId = currentDeal.dealId;
      const filesCount = files.length || 0;

      let mediaItemIds: Array<string>;

      if (uploadState[acDealId]) {
        const { uploadSession, uploadCount } = uploadState[acDealId];

        if (uploadSession) {
          mediaItemIds = await JoyUploadHelper.addToSession(uploadSession, filesCount);

          setUploadCount(acDealId, filesCount + uploadCount);
        } else {
          setUploadCount(acDealId, filesCount);
          setUploadedCount(acDealId, 0);

          const { id: sessionId, mediumIds } = await JoyUploadHelper.createSession(
            JoyUploadSessionType.mediumsAddedToAlbum,
            [JoyProperty.photosession],
            filesCount,
            currentDealId,
            currentPhotosession.id
          );

          setUploadSession(acDealId, sessionId);
          mediaItemIds = mediumIds;
        }
      } else {
        setUploadCount(acDealId, filesCount);
        setUploadedCount(acDealId, 0);

        const { id: sessionId, mediumIds } = await JoyUploadHelper.createSession(
          JoyUploadSessionType.mediumsAddedToAlbum,
          [JoyProperty.photosession],
          filesCount,
          currentDealId,
          currentPhotosession.id
        );

        setUploadSession(acDealId, sessionId);
        mediaItemIds = mediumIds;
      }

      for (let i = 0; i < filesCount; i++) {
        const mediaId = mediaItemIds[i];
        const buffer = await files[i].arrayBuffer();
        const fileName = files[i].name;
        const mimeType = files[i].type;
        const extension = fileName.split('.').pop();
        let type: MediaType;

        switch (mimeType.split('/')[0]) {
          case 'image':
            type = MediaType.image;
            break;
          case 'video':
            type = MediaType.video;
            break;
          default:
            type = MediaType.other;
            break;
        }

        if (type === MediaType.other) {
          setUploadingItems(acDealId, {
            [mediaId]: {
              thumbnailUrl: '',
              type,
              name: fileName,
              status: `Failed. File type '${extension}' not supported`
            }
          });
        } else {
          let data: JoyFieldMap = new Map<JoyField, any>();
          data[JoyField.user] = currentUser?.id;
          data[JoyField.medium] = mediaId;
          data[JoyField.data] = buffer;
          data[JoyField.extension] = extension;
          data[JoyField.mimeType] = mimeType;

          setUploadingItems(acDealId, {
            [mediaId]: {
              thumbnailUrl: UrlHelper.createObjectUrl(files[i]),
              type,
              name: fileName,
              status: 'Waiting for upload'
            }
          });

          try {
            const uploadTask = JoyUploadService.instance.upload(
              JoyUploadServiceUploadType.media,
              data,
              async (result: any) => {
                const { uploadState } = this.props;
                const uploadingItems = this.uploadingItemsAsObject(acDealId, uploadState);

                if (uploadingItems) {
                  const item = uploadingItems[mediaId];

                  if (typeof result === 'string') {
                    if (uploadingItems[mediaId].progress === 100) {
                      const medium = await JoyMediumHelper.get(mediaId, currentDealId);
                      const observer = new JoyObjectObserver((updates) => {
                        if (updates.includes(JoyField.status) && medium.status === JoyStatus.processed) {
                          const { medium, observer } = uploadingItems[mediaId];

                          if (medium && observer) {
                            medium.removeObserver(observer);
                          }

                          removeUploadingItem(acDealId, mediaId);
                          addToUploadedCount(acDealId);
                        }

                        this.checkUploadState(acDealId);
                      });
                      medium.addObserver(observer);

                      setUploadingItems(acDealId, {
                        [mediaId]: { ...item, status: 'Processing...', medium, observer, progress: undefined }
                      });
                      removeUploadingTask(acDealId, mediaId);
                    }
                  } else if (result?.name === 'FirebaseError') {
                    if (result?.code === 'storage/canceled') {
                      removeUploadingItem(acDealId, mediaId);
                      removeUploadingTask(acDealId, mediaId);
                      addToUploadedCount(acDealId);
                    } else {
                      setUploadingItems(acDealId, {
                        [mediaId]: { ...item, status: 'Failed to upload.', progress: undefined }
                      });
                    }

                    this.checkUploadState(acDealId);
                  }
                }
              },
              (progress) => {
                const { uploadState } = this.props;
                const uploadingItems = this.uploadingItemsAsObject(acDealId, uploadState);

                if (uploadingItems) {
                  const item = uploadingItems[mediaId];
                  setUploadingItems(acDealId, { [mediaId]: { ...item, progress } });
                }
              }
            );

            setUploadingTasks(acDealId, { [mediaId]: uploadTask });
          } catch (error) {
            const { uploadState } = this.props;

            if (uploadState[acDealId]) {
              let { uploadingItems } = uploadState[acDealId];

              if (uploadingItems) {
                const item = uploadingItems[mediaId];
                delete item.progress;

                setUploadingItems(acDealId, { [mediaId]: { ...item, status: 'Failed to upload.' } });
              }
            }
          }
        }
      }
    }
  };

  checkUploadState = (acDealId: string) => {
    const { uploadState, setUploadCount, setUploadedCount } = this.props;

    if (uploadState[acDealId]) {
      const { uploadCount, uploadedCount } = uploadState[acDealId];

      if (uploadCount === uploadedCount) {
        setUploadCount(acDealId, 0);
        setUploadedCount(acDealId, 0);
      }
    }
  };

  generateItemUrl = (mediaId: string | undefined): string => {
    const { currentDeal } = this.props;

    if (currentDeal && mediaId) {
      return ROUTES.homeClientMedia.replace(':clientId', currentDeal.id).replace(':mediaId', mediaId);
    }

    return '';
  };

  handleSharePublicLink = () => {
    const { currentDeal, currentPhotosession, selectedEmail } = this.props;

    if (currentDeal && currentPhotosession && selectedEmail) {
      this.setState({ shareLoading: true }, async () => {
        let photosessionId = currentPhotosession.id;

        try {
          if (currentPhotosession.properties.includes(JoyProperty.photosession)) {
            photosessionId = await JoyPhotosessionHelper.getWatermark(currentPhotosession.id, currentDeal.id);
          }
        } catch (error) {
          SnackbarHelper.showError(`Request failed for client '${selectedEmail}'!`);

          this.setState({ shareLoading: false });

          return;
        }

        const publicUrl = ROUTES.session.replace(':photosessionId', photosessionId).replace(':dealId', currentDeal.id);

        try {
          await sendAlbumUrl(selectedEmail, currentDeal.id, photosessionId);

          SnackbarHelper.showInfo(
            <>
              Share request has been sent to '{selectedEmail}' for this
              <a href={publicUrl} target="_blank" rel="noopener noreferrer">
                <Text color="white" variant="body" tag="span">
                  <b> photosession</b>
                </Text>
              </a>
              .
            </>
          );
        } catch (error) {
          SnackbarHelper.showError(
            <>
              Could not share this
              <a href={publicUrl} target="_blank" rel="noopener noreferrer">
                <Text color="white" variant="body" tag="span">
                  <b> photosession </b>
                </Text>
              </a>
              to client '{selectedEmail}'!
            </>
          );
        } finally {
          this.setState({ shareLoading: false });
        }
      });
    }
  };

  showPreviewPhotosession = async () => {
    const { currentDeal, currentPhotosession } = this.props;

    if (currentDeal && currentPhotosession) {
      let photosessionId = currentPhotosession.id;

      try {
        if (currentPhotosession.properties.includes(JoyProperty.watermark)) {
          let photosessions = await JoyDealHelper.getPhotosessions(currentDeal.id);

          const demoSession = photosessions.find((item) => item.properties.includes(JoyProperty.photosession)) || null;

          if (demoSession) {
            photosessionId = demoSession.id;
          }
        }
      } catch (error) {
        SnackbarHelper.showError(`Request failed for preview photosession!`);

        return;
      }

      const previewSessionUrl = ROUTES.previewSession
        .replace(':dealId', currentDeal.id)
        .replace(':photosessionId', photosessionId);

      window.open(previewSessionUrl, '_blank');
    }
  };

  get mediaItemInfo() {
    const { selectedId, currentPhotosessionMedia: media } = this.props;

    let itemIndex = media.findIndex((item) => item.id === selectedId);

    let prevUrl = '',
      nextUrl = '',
      itemsLength = media.length;

    if (itemsLength > 1) {
      prevUrl = this.generateItemUrl(media[itemIndex - 1]?.id || media[itemsLength - 1].id);
      nextUrl = this.generateItemUrl(media[itemIndex + 1]?.id || media[0].id);
    }

    return { item: media[itemIndex], prevUrl, nextUrl };
  }

  get mediaActions() {
    const {
      state: { selectedItems, showLoading, shareLoading },
      props: { /*uploadState, */ watermarkController, loading, currentDeal, currentPhotosessionMedia }
    } = this;

    if (!currentDeal) {
      return null;
    }

    // const uploadCount = uploadState[currentDeal.dealId] ? uploadState[currentDeal.dealId].uploadCount : 0;
    const selectedLength = selectedItems.length;
    const allMediaLength = currentPhotosessionMedia.length;
    const disableAction = loading || showLoading;

    return (
      <div className={styles.mediaActions}>
        {selectedLength > 0 ? (
          <div className={styles.selectionActions}>
            <IconButton icon={CloseIcon} disabled={disableAction} onClick={this.handleDeselectAll} />

            <div className={styles.selectionButtons}>
              {allMediaLength > 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  disabled={disableAction}
                  onClick={() => {
                    this.setState({ openDeleteDialog: true, deleteAction: 'all' });
                  }}
                >
                  Delete all {allMediaLength} items
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="outlined"
                color="secondary"
                disabled={disableAction}
                onClick={() => {
                  this.setState({ openDeleteDialog: true, deleteAction: 'selected' });
                }}
              >
                Delete {selectedLength} {selectedLength > 1 ? 'items' : 'item'}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.gridActions}>
            {this.isUserPhotoeditor || (
              <Button
                color="secondary"
                variant="transparent"
                disabled={disableAction || shareLoading}
                className={styles.shareLink}
                onClick={this.handleSharePublicLink}
                isBusy={shareLoading}
              >
                Share
              </Button>
            )}

            <div className={styles.photosessionButtons}>
              {watermarkController}

              {this.isUserPhotoeditor ? (
                <FileUploadWrapper
                  className={styles.addMedia}
                  name="clientMedia"
                  disabled={disableAction}
                  onChange={this.handleAddMedia}
                  // clearInput={!!uploadCount}
                >
                  <Button size="large" color={disableAction ? 'disabled' : 'primary'}>
                    Add media
                  </Button>
                </FileUploadWrapper>
              ) : (
                <Button
                  className={styles.addMedia}
                  color="primary"
                  variant="filled"
                  disabled={disableAction}
                  onClick={this.showPreviewPhotosession}
                >
                  Preview
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  get parsedUploadingItems(): Array<UploadItemProps> {
    const { currentDeal, uploadState, removeUploadingItem, addToUploadedCount } = this.props;

    if (currentDeal && uploadState[currentDeal.dealId]) {
      const { uploadingItems, uploadingTasks } = uploadState[currentDeal.dealId];

      if (uploadingItems) {
        return Object.entries(uploadingItems).map(([id, data]) => ({
          id,
          ...data,
          onCancel: async () => {
            try {
              if (uploadingTasks) {
                const task = uploadingTasks[id];

                if (task) {
                  task.cancel();
                }
              }

              if (uploadingItems && currentDeal) {
                const item = uploadingItems[id];

                if (item && typeof item.progress === 'undefined') {
                  removeUploadingItem(currentDeal.dealId, id);
                  addToUploadedCount(currentDeal.dealId);
                }

                await JoyMediumHelper.delete(id, currentDeal.id);

                this.checkUploadState(currentDeal.dealId);
              }
            } catch (error) {}
          }
        }));
      }
    }
    return [];
  }

  uploadingItemsAsObject = (dealId: string, uploadState: UploadState) => {
    if (uploadState[dealId]) {
      const { uploadingItems } = uploadState[dealId];

      return uploadingItems;
    }

    return null;
  };

  get uploadProgress() {
    const { currentDeal, uploadState } = this.props;

    if (currentDeal && uploadState[currentDeal.dealId]) {
      const { uploadCount, uploadedCount } = uploadState[currentDeal.dealId];

      if (uploadCount && uploadedCount) {
        return uploadCount > 0 ? (100 * uploadedCount) / uploadCount : 0;
      }
    }

    return 0;
  }

  handleItemSelect = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    const { selectedItems } = this.state;
    let itemId = event.currentTarget.getAttribute('data-value') || '';

    if (selectedItems.includes(itemId)) {
      this.setState({ selectedItems: selectedItems.filter((item) => item !== itemId) });
    } else {
      this.setState({ selectedItems: [...selectedItems, itemId] });
    }
  };

  handleDeselectAll = () => {
    this.setState({ selectedItems: [] });
  };

  closeDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleDialogResponse = (response: boolean) => {
    if (response) {
      const {
        state: { selectedItems, deleteAction },
        props: { currentDeal, currentPhotosessionMedia, loadMedia }
      } = this;

      if (currentDeal) {
        this.setState({ showLoading: true }, async () => {
          if (deleteAction === 'all') {
            for (const medium of currentPhotosessionMedia) {
              await JoyMediumHelper.delete(medium.id, currentDeal.id);
            }
          } else {
            for (const mediumId of selectedItems) {
              await JoyMediumHelper.delete(mediumId, currentDeal.id);
            }
          }

          this.setState({ selectedItems: [], showLoading: false, deleteAction: 'selected' }, () => {
            loadMedia(currentDeal.id);
          });
        });
      }
    }
  };

  toggleUploadQueue = () => {
    this.setState((prevState) => ({ showQueueWidget: !prevState.showQueueWidget }));
  };

  render() {
    const {
      state: { selectedItems, openDeleteDialog, showLoading, showQueueWidget },
      props: { loading, currentDeal, selectedId, modalCloseUrl, currentPhotosessionMedia, uploadState }
    } = this;

    let uploadCount = 0,
      uploadedCount = 0;

    if (currentDeal && uploadState[currentDeal.dealId]) {
      uploadCount = uploadState[currentDeal.dealId].uploadCount;
      uploadedCount = uploadState[currentDeal.dealId].uploadedCount;
    }

    const loadingCondition: boolean = loading || showLoading;

    return (
      <div className={styles.wrapper}>
        {this.mediaActions}

        {loadingCondition ? (
          <LoadingLoop color="disabled" className={styles.mediaLoadingLoop} />
        ) : currentDeal ? (
          <>
            <MediaGrid
              items={currentPhotosessionMedia}
              simpleItem={!this.isUserPhotoeditor}
              selectedItems={selectedItems}
              showSelected={selectedItems.length > 0}
              generateItemUrl={this.generateItemUrl}
              onSelect={this.handleItemSelect}
            />
          </>
        ) : (
          <Text className={styles.mediaGridMessage} variant="body" color="muted">
            Select a client to view media.
          </Text>
        )}

        <MediaModal
          open={!!selectedId && !loadingCondition}
          mediaItem={this.mediaItemInfo}
          modalCloseUrl={modalCloseUrl}
        />

        <DialogTemplate
          open={openDeleteDialog}
          yesLabel="Yes, delete items"
          noLabel="Cancel, do not delete"
          onClose={this.closeDeleteDialog}
          onResponse={this.handleDialogResponse}
        >
          <Text variant="heading" className={styles.dialogTitle}>
            Delete selected items ?
          </Text>

          <Text variant="subtitle" className={styles.dialogSubtitle}>
            All deleted items will be lost forever. Please make sure you have backups.
          </Text>
        </DialogTemplate>

        {showQueueWidget ? (
          <UploadQueue
            open={!!uploadCount}
            items={this.parsedUploadingItems}
            label="Upload queue"
            onClose={this.toggleUploadQueue}
          />
        ) : (
          <SnackbarTemplate
            open={!!uploadCount}
            label={`Uploading... ${uploadCount - uploadedCount} ${'items'} remaining.`}
            actions={
              <Button color="white" variant="transparent" onClick={this.toggleUploadQueue}>
                View
              </Button>
            }
            progress={this.uploadProgress}
          />
        )}
      </div>
    );
  }
}

export default connector(MediaContainer);
