import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyMedium, JoyStatus, JoyMediumsObserver, JoyChangeType } from 'joy-core';
import { JoyPhotosession, JoyPhotosessionHelper } from 'joy-crm';

import ContentViewTemplate from '@template/Pages/ContentViewTemplate';
import MediaGrid from '@sections/MediaManager/MediaGrid';
import MediaModal from '../MediaModal';
import Header from '@template/Header';
import Text from '@components/Text';
import LoadingLoop from '@components/LoadingLoop';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import styles from './PreviewPhotosession.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  userLocation: system.location,
  isMobile: system.isMobile
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MatchParams {
  photosessionId: string;
  dealId: string;
  mediaId?: string;
}

interface PageProps extends PropsFromRedux, RouteComponentProps<MatchParams> {}

interface PageState {
  loading: boolean;
  currentDealId: string | null;
  currentPhotosession: JoyPhotosession | null;
  currentPhotosessionMedia: Array<JoyMedium>;
}

class PreviewPhotosession extends Component<PageProps, PageState> {
  mediumsObserver: JoyMediumsObserver | null = null;

  constructor(props: PageProps) {
    super(props);

    this.state = {
      loading: true,
      currentDealId: null,
      currentPhotosession: null,
      currentPhotosessionMedia: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: PageProps, prevState: PageState) {
    const {
      match: {
        params: { photosessionId: prevPhotosessionId, dealId: prevDealId }
      }
    } = prevProps;
    const {
      match: {
        params: { photosessionId, dealId }
      }
    } = this.props;
    const { currentDealId, currentPhotosession } = this.state;

    if (photosessionId && dealId && (prevDealId !== dealId || prevPhotosessionId !== photosessionId)) {
      this.setMediumsObserver(dealId, photosessionId);
    }

    if (prevState.currentPhotosession === null && currentPhotosession && currentDealId) {
      this.setMediumsObserver(currentDealId, currentPhotosession.id);
    }
  }

  setMediumsObserver(dealId: string, photosessionId: string) {
    if (this.mediumsObserver) {
      this.mediumsObserver.dispose();
    }

    this.mediumsObserver = new JoyMediumsObserver(
      dealId,
      ([items, changes]) => {
        for (const change of changes) {
          if (
            change.type === JoyChangeType.removed ||
            (change.type === JoyChangeType.added && items.length !== this.state.currentPhotosessionMedia.length)
          ) {
            this.loadData();
            break;
          }
        }
      },
      photosessionId
    );

    this.mediumsObserver.setObservableCount(0);
  }

  loadData = () => {
    this.setState({ loading: true }, async () => {
      const {
        match: {
          params: { photosessionId, dealId }
        }
      } = this.props;

      try {
        const currentPhotosession = await JoyPhotosessionHelper.get(photosessionId, dealId);

        if (currentPhotosession) {
          const currentPhotosessionMedia =
            (await JoyPhotosessionHelper.getMediums(
              currentPhotosession.id,
              dealId,
              0,
              [JoyStatus.processed],
              undefined,
              false
            )) || [];

          this.setState({ currentDealId: dealId, currentPhotosession, currentPhotosessionMedia, loading: false });
        } else {
          throw Error('Preview photosession not found.');
        }
      } catch (error) {
        this.props.history.push(ROUTES.error404);
      }
    });
  };

  get modalCloseUrl() {
    const {
      match: {
        params: { photosessionId, dealId }
      }
    } = this.props;

    return ROUTES.previewSession.replace(':photosessionId', photosessionId).replace(':dealId', dealId);
  }

  generateItemUrl = (mediaId: string | undefined): string => {
    if (mediaId) {
      const {
        match: {
          params: { photosessionId, dealId }
        }
      } = this.props;

      return ROUTES.previewSessionMedia
        .replace(':photosessionId', photosessionId)
        .replace(':dealId', dealId)
        .replace(':mediaId', mediaId);
    }

    return '';
  };

  get mediaItemInfo() {
    const {
      state: { currentPhotosessionMedia: items },
      props: {
        match: {
          params: { mediaId }
        }
      }
    } = this;

    let itemIndex = items.findIndex((item) => item.id === mediaId);

    let prevUrl = '',
      nextUrl = '',
      itemsLength = items.length;

    if (itemsLength > 1) {
      prevUrl = this.generateItemUrl(items[itemIndex - 1]?.id || items[itemsLength - 1].id);
      nextUrl = this.generateItemUrl(items[itemIndex + 1]?.id || items[0].id);
    }

    return { item: items[itemIndex], prevUrl, nextUrl };
  }

  render() {
    const {
      state: { loading, currentPhotosession, currentPhotosessionMedia },
      props: {
        match: {
          params: { mediaId }
        },
        isMobile
      }
    } = this;

    return (
      <ContentViewTemplate header={<Header logoExtension="portrait" fixedHeader />} fixedHeader>
        {loading ? (
          <LoadingLoop color="disabled" />
        ) : (
          <>
            <Text className={classNames(styles.clientName, { [styles.clientNameMobile]: isMobile })} variant="heading">
              {currentPhotosession?.name}
            </Text>

            <MediaGrid items={currentPhotosessionMedia} simpleItem publicView generateItemUrl={this.generateItemUrl} />

            <MediaModal open={!!mediaId} mediaItem={this.mediaItemInfo} modalCloseUrl={this.modalCloseUrl} />
          </>
        )}
      </ContentViewTemplate>
    );
  }
}

export default connector(PreviewPhotosession);
