import React, { Component } from 'react';

import styles from './AuthPageTemplate.module.scss';
import JoyLogo from '@components/JoyLogo';
import Button from '@components/Button';

interface AuthPageProps {
  children?: React.ReactNode;
  pageExtension?: React.ReactNode;
  legend?: React.ReactNode;
  cardActionsExtension?: React.ReactNode;
  submitText: string;
  isBusy?: boolean;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent) => void;
}

class AuthPageTemplate extends Component<AuthPageProps> {
  handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.props.onSubmit(event);
    }
  };

  render() {
    const { children, pageExtension, legend, cardActionsExtension, submitText, isBusy = false, onSubmit } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.card} onKeyDownCapture={this.handleKeyDown}>
          <a href={process.env.REACT_APP_JOY_LINK}>
            <JoyLogo />
          </a>

          <div className={styles.legend}>{legend}</div>

          {children}

          <div className={styles.cardActions}>
            <div className={styles.cardActionsExtension}>{cardActionsExtension}</div>

            <Button color="secondary" onClick={onSubmit} isBusy={isBusy}>
              {submitText}
            </Button>
          </div>
        </div>
        <div className={styles.underCardExtension}>{pageExtension}</div>
      </div>
    );
  }
}

export default AuthPageTemplate;
