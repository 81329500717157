import React from 'react';
import classNames from 'classnames';

import styles from './IconTemplate.module.scss';

interface IconGenericProps {
  padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  size?: number;
  className?: string;
}

interface IconTemplateGenericProps extends IconGenericProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface IconMainTemplateProps extends IconTemplateGenericProps {
  fill?: 'none' | 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white' | 'black';
  stroke?: 'none' | 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white' | 'black';
}

interface IconTemplateProps extends IconTemplateGenericProps {
  color?: 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white' | 'black';
}

export interface IconProps extends IconGenericProps {
  color?: 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white' | 'black';
}

function IconTemplate({
  icon: Icon,
  fill = 'none',
  stroke = 'none',
  padding = 'none',
  size = 24,
  className = ''
}: IconMainTemplateProps) {
  return (
    <Icon
      className={classNames(styles[`${fill}FillColor`], styles[`${stroke}StrokeColor`], styles[`${padding}Padding`], {
        [className]: Boolean(className)
      })}
      width={size}
      height={size}
    />
  );
}

export function IconFill({ color = 'default', ...otherProps }: IconTemplateProps) {
  return <IconTemplate {...otherProps} fill={color} />;
}

export function IconStroke({ color = 'default', ...otherProps }: IconTemplateProps) {
  return <IconTemplate {...otherProps} stroke={color} />;
}

export default IconTemplate;
