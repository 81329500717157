import store from '@utils/redux/store';
import { setCurrentUser, resetSession } from '@utils/redux/user/actions';
import { resetClient } from '@utils/redux/client/actions';
import { resetUpload } from '@utils/redux/upload/actions';
import { JoyAuthService, JoyProperties, JoyProperty, JoyUserHelper } from 'joy-core';

interface UserCreateProps {
  name: string;
  primaryEmail: string;
  password: string;
  photoUrl?: string;
}

class AuthHelper {
  static async checkUserExists(
    email: string
  ): Promise<{
    exists: boolean;
    isAnonymous: boolean | null;
    properties: JoyProperties;
  } | null> {
    try {
      const result = await JoyUserHelper.findByEmail(email.toLowerCase());

      return result;
    } catch (error) {
      return null;
    }
  }

  static async createUser({ name, primaryEmail, password }: UserCreateProps): Promise<object | null> {
    try {
      const result = await JoyUserHelper.create(primaryEmail, password, name);

      return result;
    } catch (error) {
      return null;
    }
  }

  static async setAuthUserData(userId: string): Promise<void> {
    const state = store.getState();
    const { currentUser } = state.user;

    if (currentUser === null && this.isAuthenticated) {
      const newUser = await JoyUserHelper.get(userId);

      store.dispatch(setCurrentUser(newUser));
    } else if (!this.isAuthenticated) {
      store.dispatch(setCurrentUser(null));
    }
  }

  static async sendResetPasswordEmail(email: string): Promise<boolean> {
    try {
      return await JoyAuthService.instance.sendResetPasswordEmail(email.toLowerCase());
    } catch (error) {
      return false;
    }
  }

  static async resetPassword(token: string, password: string): Promise<boolean> {
    try {
      return await JoyAuthService.instance.resetPassword(token, password);
    } catch (error) {
      return false;
    }
  }

  static async signIn(email: string, password: string): Promise<boolean> {
    try {
      const token = await JoyAuthService.instance.emailAuth(email.toLowerCase(), password);
      const userId = await JoyAuthService.instance.createSession(token);
      const user = await JoyUserHelper.get(userId);

      store.dispatch(setCurrentUser(user));

      return true;
    } catch (error) {
      await this.signOut();

      return false;
    }
  }

  static async signOut(): Promise<boolean> {
    store.dispatch(resetClient());
    store.dispatch(resetSession());
    store.dispatch(resetUpload());

    return JoyAuthService.instance.deleteSession();
  }

  static get isAuthenticated(): boolean {
    return Boolean(JoyAuthService.instance.isAuthenticated());
  }

  static userHasRole(role: JoyProperty): boolean {
    const state = store.getState();
    const { currentUser } = state.user;

    if (currentUser) {
      return currentUser.properties.includes(role);
    }

    return false;
  }
}

export default AuthHelper;
