import React from 'react';
import classNames from 'classnames';

import LoadingLoop from '@components/LoadingLoop';
import styles from './Button.module.scss';

interface ButtonProps {
  children: React.ReactNode;
  color?: 'default' | 'disabled' | 'muted' | 'primary' | 'secondary' | 'error' | 'white';
  variant?: 'filled' | 'outlined' | 'transparent';
  size?: 'small' | 'normal' | 'large';
  disabled?: boolean;
  isBusy?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function Button({
  children,
  color = 'default',
  variant = 'filled',
  size = 'normal',
  disabled = false,
  isBusy = false,
  className = '',
  onClick
}: ButtonProps) {
  let classes: string;
  let loadingColor: ButtonProps['color'];

  switch (variant) {
    default:
    case 'filled':
      classes = classNames(styles.button, styles.buttonFilled, styles[`${size}Size`], styles[`${color}ColorFilled`], {
        [className]: Boolean(className),
        [styles.disabledColorFilled]: disabled
      });
      loadingColor = disabled ? 'disabled' : 'white';

      break;
    case 'outlined':
      classes = classNames(
        styles.button,
        styles.buttonOutlined,
        styles[`${size}Size`],
        styles[`${color}ColorOutlined`],
        {
          [className]: Boolean(className),
          [styles.disabledColorOutlined]: disabled
        }
      );
      loadingColor = disabled ? 'disabled' : color;

      break;
    case 'transparent':
      classes = classNames(
        styles.button,
        styles.buttonOutlined,
        styles.transparent,
        styles[`${size}Size`],
        styles[`${color}ColorOutlined`],
        {
          [className]: Boolean(className),
          [styles.disabledColorOutlined]: disabled
        }
      );
      loadingColor = disabled ? 'disabled' : color;

      break;
  }

  return (
    <button className={classes} onClick={disabled || isBusy ? undefined : onClick}>
      {isBusy ? <LoadingLoop color={loadingColor} /> : children}
    </button>
  );
}

export default Button;
