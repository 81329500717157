import React from 'react';

import bountyWhite from '@assets/img/bounty.png';
import styles from './BountyLogo.module.scss';

interface LogoProps {
  height?: number;
}

function BountyLogo({ height = 42 }: LogoProps) {
  return <img className={styles.image} src={bountyWhite} alt="bounty logo" height={height} />;
}

export default BountyLogo;
