import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { JoyPhotosession, JoyPhotosessionHelper } from 'joy-crm';

import ContentViewTemplate from '@template/Pages/ContentViewTemplate';
import Header from '@template/Header';
import Text from '@components/Text';
import LoadingLoop from '@components/LoadingLoop';
import LanguageSelector from '@components/LanguageSelector';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import styles from './Checkout.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  userLocation: system.location,
  isMobile: system.isMobile,
  isTablet: system.isTablet,
  isSmallTablet: system.isSmallTablet,
  isLargeTablet: system.isLargeTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MatchParams {
  photosessionId: string;
  dealId: string;
}

interface LinkState {
  plan: number;
  photosessionId?: string;
  media?: Array<string>;
  description?: string;
}

interface PageProps extends PropsFromRedux, RouteComponentProps<MatchParams> {}

interface PageState {
  loading: boolean;
  currentDealId: string | null;
  currentPhotosession: JoyPhotosession | null;
  linkState: LinkState | null;
}

class Checkout extends Component<PageProps, PageState> {
  paywhirlWindow: Window | null = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentDealId: null,
      currentPhotosession: null,
      linkState: null
    };
  }

  componentDidMount() {
    const {
      location: { state, pathname },
      history
    }: any = this.props;

    this.setState({ linkState: state || { plan: -1 } }, () => {
      history.replace(pathname, undefined);

      this.loadData();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener, true);
  }

  loadData = () => {
    this.setState({ loading: true }, async () => {
      const {
        match: {
          params: { photosessionId, dealId }
        }
      } = this.props;

      try {
        const currentPhotosession = await JoyPhotosessionHelper.get(photosessionId, dealId);

        if (currentPhotosession) {
          this.setState({ currentDealId: dealId, currentPhotosession, loading: false }, this.handleWidget);
        } else {
          throw Error('Watermark photosession not found.');
        }
      } catch (error) {
        this.props.history.push(ROUTES.error404);
      }
    });
  };

  handleLanguageChange = () => {
    const {
      location: { pathname },
      history
    }: any = this.props;

    this.setState({ linkState: { plan: -1 } }, () => {
      const widgetFrame = document.getElementById('pwframe_paywhirl_widget_anchor');

      if (widgetFrame) {
        widgetFrame.remove();
      }

      history.replace(pathname, undefined);

      this.loadData();
    });
  };

  get widgetLocationConfig() {
    const { userLocation } = this.props;

    if (userLocation === 'US')
      return {
        widgetId: process.env.REACT_APP_PAYWHIRL_WIDGET_US,
        widgetDomain: process.env.REACT_APP_PAYWHIRL_DOMAIN_US
      };
    else
      return {
        widgetId: process.env.REACT_APP_PAYWHIRL_WIDGET_GB,
        widgetDomain: process.env.REACT_APP_PAYWHIRL_DOMAIN_GB
      };
  }

  handleWidget = () => {
    const paywhirl: Function | undefined = window['paywhirl'];
    const { widgetId, widgetDomain } = this.widgetLocationConfig;

    if (paywhirl) {
      paywhirl(
        'widget',
        {
          autoscroll: 1,
          initial_autoscroll: 0,
          domain: widgetDomain,
          uuid: widgetId
        },
        'paywhirl_widget_anchor'
      );
    } else {
      this.props.history.push(ROUTES.error404);
    }

    window.addEventListener('message', this.messageListener, true);

    const paywhirlIframe: HTMLIFrameElement | null = document.querySelector('#pwframe_paywhirl_widget_anchor');

    this.paywhirlWindow = paywhirlIframe ? paywhirlIframe?.contentWindow : null;
  };

  messageListener = (event: MessageEvent) => {
    const { data, origin } = event;
    const { widgetDomain } = this.widgetLocationConfig;

    if (origin !== `https://${widgetDomain}.paywhirl.com`) return;

    if (typeof data !== 'object') return;
    if (data?.source !== 'joy-paywhirl') return;

    switch (data.message) {
      case 'widget-loaded':
        switch (data.pathname) {
          case `/widget/${process.env.REACT_APP_PAYWHIRL_WIDGET_GB}`:
          case `/widget/${process.env.REACT_APP_PAYWHIRL_WIDGET_US}`:
            if (this.paywhirlWindow !== null) {
              const { linkState } = this.state;

              if (linkState?.description) {
                this.paywhirlWindow.postMessage(
                  { source: 'joy-checkout', message: 'auto-select', plan: linkState.plan },
                  '*'
                );
              } else {
                this.paywhirlWindow.postMessage({ source: 'joy-checkout', message: 'manual-select' }, '*');
              }
            }
            break;
          case `/widget/${process.env.REACT_APP_PAYWHIRL_WIDGET_MEDIA_GB}`:
          case `/widget/${process.env.REACT_APP_PAYWHIRL_WIDGET_MEDIA_US}`:
            if (this.paywhirlWindow !== null) {
              const { linkState } = this.state;

              if (linkState?.description) {
                this.paywhirlWindow.postMessage(
                  { source: 'joy-checkout', message: 'edit-product', description: linkState?.description },
                  '*'
                );
              }
            }
            break;
          case '/cart':
            if (this.paywhirlWindow !== null) {
              const { currentPhotosession } = this.state;

              if (currentPhotosession) {
                this.paywhirlWindow.postMessage(
                  { source: 'joy-checkout', message: 'edit-sign-form', email: currentPhotosession.description },
                  '*'
                );
              }
            }
            break;
          case '/dashboard':
            this.props.history.push(ROUTES.checkoutResponse);
            break;
          default:
        }
        break;
      case 'selected-manually':
        this.props.history.push({
          pathname: this.generatePhotosessionUrl(),
          state: { plan: data.plan }
        });
        break;
      default:
        break;
    }
  };

  generatePhotosessionUrl() {
    const { currentDealId, currentPhotosession } = this.state;

    if (currentDealId && currentPhotosession) {
      return ROUTES.session.replace(':dealId', currentDealId).replace(':photosessionId', currentPhotosession.id);
    }

    return '';
  }

  get headerContent() {
    return (
      <Header
        logoExtension="portrait"
        useLocation
        content={<LanguageSelector onChange={this.handleLanguageChange} />}
      />
    );
  }

  render() {
    const {
      state: { currentPhotosession, loading, linkState },
      props: { isMobile, isLargeTablet }
    } = this;
    const displayHero = (linkState && linkState.plan === -1) || linkState === null;

    return (
      <ContentViewTemplate header={this.headerContent} noWrapper>
        <div className={isLargeTablet ? styles.wrapperMobile : styles.wrapper}>
          {displayHero && (
            <img
              src="/img/hero-section.png"
              alt=""
              className={isLargeTablet ? styles.heroImageMobile : styles.heroImage}
            />
          )}

          {loading ? (
            <LoadingLoop color="disabled" />
          ) : displayHero ? (
            <div className={styles.heroTitle}>
              <Text variant="titleLarge">Choose your package</Text>
              {isMobile && <br />}
              <Text variant="titleSmall">
                Select a package that is best suited for you to have the full Joy experience with your friends and
                family.
              </Text>
            </div>
          ) : (
            <Text className={styles.title}>Checkout{currentPhotosession && ` - ${currentPhotosession.name}`}</Text>
          )}

          <div id="paywhirl_widget_anchor" className={styles.widgetContainer}></div>

          {displayHero && (
            <>
              <div className={styles.sectionTestimonial}>
                <img src="/img/mary-lauren-avatar.jpg" alt="" className={styles.avatar} />

                <Text variant="paragraph">Mary Lauren</Text>
                <Text variant="body">BLOGGER AND MOTHER OF 2</Text>

                <hr className={styles.separator} />

                <Text className={isMobile ? styles.quoteMobile : styles.quote}>
                  "Most importantly Joy has brought our family back together"
                </Text>
              </div>

              <div className={styles.sectionBrilliant}>
                <Text variant="heading">
                  Brilliant Display <br />
                  10.1" touchscreen, Full HD display, 178º viewing angle
                </Text>
                <div className={styles.tabletBackground}></div>
              </div>

              <div className={styles.sectionUseSteps}>
                <div className={styles.sectionContent}>
                  <div className={styles.sectionCard}>
                    <div className={styles.cardImage} style={{ backgroundImage: 'url("/img/how01.jpg")' }}></div>
                    <Text variant="title">1. Organise</Text>
                    <br />
                    <Text variant="subtitle">
                      Create and share albums with the Joy mobile app. Add photos and videos from your favourite photos
                      services, such as Facebook, Instagram, Google and more.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <div className={styles.cardImage} style={{ backgroundImage: 'url("/img/how02.png")' }}></div>
                    <Text variant="title">2. Store</Text>
                    <br />
                    <Text variant="subtitle">
                      Your photos and videos are safely stored in Joy's secure cloud, ready to be enjoyed by the whole
                      family.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <div className={styles.cardImage} style={{ backgroundImage: 'url("/img/how03.gif")' }}></div>
                    <Text variant="title">3. Experience</Text>
                    <br />
                    <Text variant="subtitle">
                      Joy arranges your photos in to beautiful layouts. Easily share photos direct to your loved ones
                      Joy albums.
                    </Text>
                  </div>
                </div>
              </div>

              <div className={styles.sectionWhyJoy}>
                <Text variant="heading" className={styles.sectionTitle}>
                  Why Joy?
                </Text>
                <div className={styles.sectionContent}>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      Simple Private Sharing
                    </Text>
                    <Text variant="subtitle">
                      Organize your adventures with the entire family using the Joy app. Share to their email address
                      and your albums show up on their device and yours.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      Safe and Secure
                    </Text>
                    <Text variant="subtitle">
                      Joy has strict privacy controls, automatic secure cloud backup and we do not mine your personal
                      photos for advertising data.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      Slideshow Mode
                    </Text>
                    <Text variant="subtitle">
                      The dual-angle wireless charging stand turns the Joy Album into an engaging slideshow display for
                      low side tables or high shelves.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      Supports All Formats
                    </Text>
                    <Text variant="subtitle">
                      The Joy Album and Joy web app supports batch upload of a wide variety of photo and video file
                      types including jpeg, png, mov, mp4, raw and even panoramas.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      Cloud-First Storage
                    </Text>
                    <Text variant="subtitle">
                      All your memories are backed up in Joy’s cloud service and recent albums are optimized locally so
                      you can access your entire library and never run out of space.
                    </Text>
                  </div>
                  <div className={styles.sectionCard}>
                    <Text className={styles.sectionCardTitle} variant="title">
                      10” Full HD Touchscreen
                    </Text>
                    <Text variant="subtitle">
                      Joy is large enough for gathering around yet light enough to hold with one hand.
                    </Text>
                  </div>
                </div>
              </div>

              <div className={styles.sectionJoyAlbum}>
                <div className={styles.albumBackground}>
                  <div className={styles.albumVideo}></div>

                  <div className={styles.albumContent}>
                    <Text variant={isMobile ? 'body' : 'subtitle'} color="white">
                      THE JOY DIGITAL PHOTO ALBUM
                    </Text>
                    <Text variant={isMobile ? 'title' : 'titleLarge'} color="white">
                      Bringing families together,
                      <br />
                      even when they're apart.
                    </Text>
                  </div>
                </div>
              </div>

              <div className={styles.sectionCharging}>
                <div className={styles.chargingContent}>
                  <div className={styles.chargingText}>
                    <Text variant={isMobile ? 'title' : 'heading'}>Wireless Charging Technology</Text>
                    <br />
                    <Text variant={isMobile ? 'body' : 'titleSmall'}>
                      Joy has no ports and no wires so your memories are free to be enjoyed, right at your fingertips.
                    </Text>
                  </div>
                  <div className={styles.chargingBackground}></div>
                </div>
              </div>

              <div className={styles.sectionTechnical}>
                <Text variant="heading" className={styles.sectionTitle}>
                  Technical Specs
                </Text>

                <img className={styles.technicalImage} src="/img/techspecs.png" alt="techspecs.png" />

                <div className={styles.technicalSpecs}>
                  <div className={styles.sectionCard}>
                    <ol className={styles.specsList}>
                      <li>10.1" Full HD touchscreen</li>

                      <li>Volume button</li>

                      <li>Power button</li>

                      <li>Wireless charging base</li>
                    </ol>
                  </div>

                  <div className={classNames(styles.sectionCard, styles.specColumn)}>
                    <h3>Resolution:</h3>
                    <p>1920 x 1200&nbsp;Full High Definition</p>

                    <h3>Dimensions:</h3>
                    <p>10.1'' x 8.0'' x 0.4''</p>
                  </div>

                  <div className={classNames(styles.sectionCard, styles.specColumn)}>
                    <h3>Accepted digital photo file formats:</h3>
                    <p>JPEG, PNG, MOV, GIF, MP4, HD videos</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentViewTemplate>
    );
  }
}

export default connector(Checkout);
