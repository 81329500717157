import React from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import JoyLogo from '@components/JoyLogo';
import BountyLogo from '@components/BountyLogo';
import { RootState } from '@utils/redux/store';
import styles from './Header.module.scss';

const mapStateToProps = ({ system }: RootState) => ({
  language: system.location,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface HeaderProps extends PropsFromRedux {
  content?: React.ReactNode;
  logoExtension?: 'portrait' | null;
  useLocation?: boolean;
  fixedHeader?: boolean;
}

function Header({ content, logoExtension, useLocation = false, fixedHeader = false, language, isMobile }: HeaderProps) {
  return (
    <div className={classNames(styles.wrapper, { [styles.fixedHeader]: fixedHeader })}>
      <div className={classNames(styles.container, { [styles.containerMobile]: isMobile })}>
        {useLocation ? (
          language === 'US' ? (
            <JoyLogo extension={logoExtension} height={isMobile ? 20 : 32} />
          ) : (
            <BountyLogo height={isMobile ? 30 : 42} />
          )
        ) : (
          <JoyLogo extension={logoExtension} height={isMobile ? 20 : 32} />
        )}

        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
}

export default connector(Header);
