import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Replay } from 'vimond-replay';
import ShakaVideoStreamer from 'vimond-replay/video-streamer/shaka-player';
import { JoyMedium } from 'joy-core';

import ModalBase from '@components/ModalBase';
import CloseIcon from '@icons/CloseIcon';
import ArrowBackIcon from '@icons/ArrowBackIcon';
import ArrowForwardIcon from '@icons/ArrowForwardIcon';
import { RootState } from '@utils/redux/store';
import styles from './MediaModal.module.scss';
import 'vimond-replay/index.css';
import './replay-override.css';

const mapStateToProps = ({ system }: RootState) => ({
  browser: system.browser,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ModalPageProps extends PropsFromRedux {
  open: boolean;
  mediaItem: {
    item: JoyMedium;
    prevUrl: string;
    nextUrl: string;
  };
  modalCloseUrl: string;
}

class MediaModal extends Component<ModalPageProps> {
  private browserInfo = this.props.browser;
  private beResponsive = this.props.isMobile || this.props.isTablet;

  get renderedMedia() {
    const {
      mediaItem: { item }
    } = this.props;

    if (item) {
      const mimeType = item.mimeType || '';

      if (mimeType.startsWith('image')) {
        return <img className={styles.imageItem} src={item.thumbnailUri} alt={item.thumbnailUri} draggable="false" />;
      }

      if (mimeType.startsWith('video')) {
        switch (this.browserInfo?.name) {
          case 'chrome':
          case 'firefox':
          case 'edge-chromium':
          case 'edge':
          default:
            const mpdStream = item.streamUriMpd;

            if (mpdStream) {
              return (
                <div className={styles.videoItem}>
                  <Replay source={{ streamUrl: mpdStream, contentType: 'application/dash+xml' }}>
                    <ShakaVideoStreamer />
                  </Replay>
                </div>
              );
            }

            break;
          case 'safari':
          case 'ios':
            const hlsStream = item.streamUriHls;

            if (hlsStream) {
              return (
                <div className={styles.videoItem}>
                  <Replay source={{ streamUrl: hlsStream }} />
                </div>
              );
            }

            break;
        }

        return (
          <div className={styles.videoItem}>
            <Replay source={item.downloadUri} />
          </div>
        );
      }
    }

    return null;
  }

  render() {
    const {
      open,
      mediaItem: { prevUrl, nextUrl },
      modalCloseUrl
    } = this.props;

    if (!open) {
      return null;
    }

    return (
      <ModalBase>
        <Link className={this.beResponsive ? styles.closeButtonMobile : styles.closeButton} to={modalCloseUrl}>
          <CloseIcon color="muted" />
        </Link>

        {prevUrl && (
          <Link className={styles.prevButton} to={prevUrl}>
            <ArrowBackIcon size={36} color="muted" />
          </Link>
        )}

        <div className={this.beResponsive ? styles.wrapperMobile : styles.wrapper}>{this.renderedMedia}</div>

        {nextUrl && (
          <Link className={styles.nextButton} to={nextUrl}>
            <ArrowForwardIcon size={36} color="muted" />
          </Link>
        )}
      </ModalBase>
    );
  }
}

export default connector(MediaModal);
