import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { JoyUser } from 'joy-core';

import Header from '@template/Header';
import Footer from '@template/Footer';
import NativeLink from '@components/NativeLink';
import JoyLogo from '@components/JoyLogo';
import DropDown, { DropDownItem, DropDownAnchor } from '@components/DropDown';
import AuthHelper from '@helpers/AuthHelper';
import { RootState } from '@utils/redux/store';
import { ROUTES } from '@utils/system';
import styles from './ContentViewTemplate.module.scss';

const mapStateToProps = ({ user, system }: RootState) => ({
  currentUser: user.currentUser,
  isMobile: system.isMobile,
  isTablet: system.isTablet
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PageProps extends PropsFromRedux, RouteComponentProps {
  header?: React.ReactNode;
  useLocation?: boolean;
  logoExtension?: 'portrait' | null;
  footer?: React.ReactNode;
  children: React.ReactNode;
  noWrapper?: boolean;
  currentUser: JoyUser | null;
  fixedHeader?: boolean;
}

class ContentViewTemplate extends Component<PageProps> {
  handleSignOut = async () => {
    await AuthHelper.signOut();

    this.props.history.push(ROUTES.signIn);
  };

  render() {
    const {
      header,
      useLocation,
      logoExtension,
      footer,
      children,
      noWrapper,
      currentUser,
      fixedHeader,
      isMobile
      // isTablet
    } = this.props;

    return (
      <>
        {header || (
          <Header
            content={
              <DropDown anchor={<DropDownAnchor label={currentUser?.name || ''} />}>
                <DropDownItem label="Sign out" onClick={this.handleSignOut} />
              </DropDown>
            }
            useLocation={useLocation}
            logoExtension={logoExtension}
            fixedHeader={fixedHeader}
          />
        )}

        {noWrapper ? (
          <>
            <div className={classNames({ [styles.contentFiller]: fixedHeader, [styles.fixedHeader]: fixedHeader })} />

            {children}
          </>
        ) : (
          <div
            className={classNames(styles.wrapper, {
              [styles.fixedHeader]: fixedHeader,
              [styles.wrapperMobile]: isMobile
            })}
          >
            {children}
          </div>
        )}

        {footer || (
          <Footer
            leftColumn={
              <>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/terms-of-service`} target="_blank">
                  Terms
                </NativeLink>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/privacy-policy`} target="_blank">
                  Privacy
                </NativeLink>
              </>
            }
            middleColumn={<JoyLogo />}
            rightColumn={
              <>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/frequently-asked-questions`} target="_blank">
                  FAQ
                </NativeLink>
                <NativeLink href={`${process.env.REACT_APP_JOY_LINK}/pages/help-center`} target="_blank">
                  Help Center
                </NativeLink>
              </>
            }
          />
        )}
      </>
    );
  }
}

export default connector(withRouter(ContentViewTemplate));
