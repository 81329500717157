import React from 'react';
import styles from './DropDown.module.scss';
import ChevronDownIcon from '@icons/ChevronDownIcon';

interface DropDownAnchorProps {
  label: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function DropDownAnchor({ label, onClick }: DropDownAnchorProps) {
  return (
    <div className={styles.anchor} onClick={onClick}>
      {label}
      <ChevronDownIcon className={styles.anchorIcon} size={17} />
    </div>
  );
}

export default DropDownAnchor;
