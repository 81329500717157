import React from 'react';
import classNames from 'classnames';

import { IconProps } from '@icons/IconTemplate';
import styles from './IconButton.module.scss';

interface IconButtonProps extends IconProps {
  id?: string;
  icon: Function;
  disabled?: boolean;
  'data-value'?: string | number | null;
  className?: string;
  IconClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function IconButton({
  id,
  icon: Icon,
  disabled = false,
  'data-value': dataValue = null,
  IconClassName = '',
  onClick,
  ...iconProps
}: IconButtonProps) {
  const { className = '' } = iconProps;

  if (disabled) {
    iconProps.color = 'disabled';
  }

  if (IconClassName) {
    iconProps.className = IconClassName;
  }

  return (
    <button
      id={id}
      data-value={dataValue}
      className={classNames(styles.button, { [className]: Boolean(className) })}
      onClick={disabled ? undefined : onClick}
    >
      <Icon {...iconProps} />
    </button>
  );
}

export default IconButton;
