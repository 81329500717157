import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import MediaManager from '@sections/MediaManager';
import Photosession from '@sections/MediaManager/Photosession';
import PreviewPhotosession from '@sections/MediaManager/PreviewPhotosession';
import Checkout from '@sections/Checkout';
import CheckoutResponse from '@sections/Checkout/CheckoutResponse';
import SignIn from '@sections/Auth/SignIn';
import SignUp from '@sections/Auth/SignUp';
import VerifyEmail from '@sections/Auth/VerifyEmail';
import ResetPassword from '@sections/Auth/ResetPassword';
import ForgotPassword from '@sections/Auth/ForgotPassword';
import Error404 from '@template/Errors/Error404';
import Snackbar from '@components/Snackbar';
import { ROUTES } from '@utils/system';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <main role="main">
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.signIn} component={SignIn} />
          <Route exact path={ROUTES.signUp} component={SignUp} />
          <Route exact path={ROUTES.verify} component={VerifyEmail} />
          <Route exact path={ROUTES.resetPassword} component={ResetPassword} />
          <Route exact path={ROUTES.forgotPassword} component={ForgotPassword} />

          <ProtectedRoute exact path={ROUTES.home} component={MediaManager} />
          <ProtectedRoute exact path={ROUTES.homeClient} component={MediaManager} />
          <ProtectedRoute exact path={ROUTES.homeClientMedia} component={MediaManager} />

          <Route exact path={ROUTES.session} component={Photosession} />
          <Route exact path={ROUTES.sessionMedia} component={Photosession} />

          <ProtectedRoute exact path={ROUTES.previewSession} component={PreviewPhotosession} />
          <ProtectedRoute exact path={ROUTES.previewSessionMedia} component={PreviewPhotosession} />

          <Route exact path={ROUTES.checkout} component={Checkout} />
          <Route exact path={ROUTES.checkoutResponse} component={CheckoutResponse} />

          <Route path={ROUTES.error404} component={Error404} />
          <Route path="*" component={Error404} />
        </Switch>
      </BrowserRouter>

      <Snackbar />
    </main>
  );
}

export default App;
