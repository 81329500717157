import React, { Component } from 'react';
import MessagePageTemplate from '@template/Pages/MessagePageTemplate';
import Text from '@components/Text';

interface PageState {}
// TODO: handle display only if all params are received
class VerifyEmail extends Component<{}, PageState> {
  get legend() {
    return <Text>Your account has been confirmed.</Text>;
  }

  render() {
    return (
      <MessagePageTemplate legend={this.legend}>
        <Text variant="body" color="muted">
          Open the Joy mobile app and sign in.
        </Text>
      </MessagePageTemplate>
    );
  }
}

export default VerifyEmail;
