import React from 'react';

import Input from '@components/Input';
import { InputProps } from './Input';

interface EmailProps extends InputProps {}

function EmailInput({ name = 'email', label = 'Email', placeholder = 'Enter email', ...otherProps }: EmailProps) {
  return (
    <Input
      type="email"
      name={name}
      label={label}
      placeholder={placeholder}
      inputProps={{ pattern: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]{2,63})$" }}
      {...otherProps}
    />
  );
}

export default EmailInput;
