import React from 'react';
import classNames from 'classnames';

import styles from './NativeLink.module.scss';

interface NativeLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  href: string;
  className?: string;
}

function NativeLink({ children, href, className = '', ...linkProps }: NativeLinkProps) {
  return (
    <a
      className={classNames(styles.link, {
        [className]: Boolean(className)
      })}
      href={href}
      {...linkProps}
    >
      {children}
    </a>
  );
}

export default NativeLink;
