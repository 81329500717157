import React from 'react';

import { ReactComponent as JoyGray } from '@assets/img/joy-logo-gray.svg';
import { ReactComponent as JoyWhite } from '@assets/img/joy-logo-white.svg';
import styles from './JoyLogo.module.scss';

interface LogoProps {
  color?: 'gray' | 'white';
  height?: number;
  extension?: 'portrait' | null;
}

const SCALE_FACTOR = 2.5517;

function JoyLogo({ color = 'gray', height = 32, extension = null }: LogoProps) {
  const width = height * SCALE_FACTOR;
  const extensionFactor = (height * 7) / 16;

  const logo =
    color === 'gray' ? <JoyGray width={width} height={height} /> : <JoyWhite width={width} height={height} />;

  switch (extension) {
    case 'portrait':
      return (
        <div className={styles.wrapper}>
          {logo}

          <div className={styles.extension} style={{ fontSize: extensionFactor }}>
            PORTRAIT
          </div>
        </div>
      );
    default:
      return logo;
  }
}

export default JoyLogo;
