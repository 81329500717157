import React, { Component } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import AuthHelper from '@helpers/AuthHelper';
import { ROUTES } from '@utils/system';
import { JoyAuthService, JoyFieldMap, JoyField, JoyListener } from 'joy-core';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps> | any; // FIXME: handle correct types
}

interface ProtectedRouteState {
  loading: boolean;
  redirectToSignIn: boolean;
}

class ProtectedRoute extends Component<ProtectedRouteProps, ProtectedRouteState> {
  private authListener: JoyListener | null;

  constructor(props: ProtectedRouteProps) {
    super(props);

    this.state = { loading: true, redirectToSignIn: false };
    this.authListener = null;
  }

  componentDidMount() {
    this.authListener = JoyAuthService.instance.authListener(async (data: JoyFieldMap) => {
      const auth = data[JoyField.auth];

      if (auth) {
        await AuthHelper.setAuthUserData(data[JoyField.user]);
        this.setState({ loading: false });
      } else {
        this.setState({ redirectToSignIn: true });
      }
    });

    this.authListener?.activate();
  }

  componentWillUnmount() {
    this.authListener?.deactivate();
    this.authListener = null;
  }

  renderRoute = (props: React.ComponentProps<any>) => {
    const { component: Component } = this.props;

    if (AuthHelper.isAuthenticated) return <Component {...props} />;

    return <Redirect to={ROUTES.signIn} />;
  };

  render() {
    if (this.state.redirectToSignIn) {
      return <Redirect to={ROUTES.signIn} />;
    }

    if (this.state.loading) {
      return null;
    }

    const { component, ...otherProps } = this.props;

    return <Route {...otherProps} render={this.renderRoute} />;
  }
}

export default ProtectedRoute;
