import React from 'react';

import styles from './Avatar.module.scss';

interface AvatarProps {
  name: string;
  photoUrl?: string;
  showName?: boolean;
}

function Avatar({ name, photoUrl, showName }: AvatarProps) {
  return (
    <div className={styles.wrapper} title={name}>
      {photoUrl ? (
        <img src={photoUrl} className={styles.photo} alt={name} draggable="false" />
      ) : (
        <Initials name={name} />
      )}
      {showName && <div className={styles.userName}>{name}</div>}
    </div>
  );
}

function Initials({ name }: any) {
  let initials = (name || '')
    .split(' ')
    .map((item: string) => item[0])
    .join('')
    .toUpperCase();

  return <div className={styles.initials}>{initials}</div>;
}

export default Avatar;
