import { JoyMediumsObserver } from 'joy-co/core/build';
import { Dispatch } from 'redux';
import { UploadingItemsType, UploadingTasksType } from './reducers';

export const RESET_UPLOAD = 'RESET_UPLOAD';
export const SET_UPLOAD_COUNT = 'SET_UPLOAD_COUNT';
export const SET_UPLOADED_COUNT = 'SET_UPLOADED_COUNT';
export const ADD_TO_UPLOADED_COUNT = 'ADD_TO_UPLOADED_COUNT';
export const SET_UPLOADING_ITEMS = 'SET_UPLOADING_ITEMS';
export const REMOVE_UPLOADING_ITEMS = 'REMOVE_UPLOADING_ITEMS';
export const REMOVE_UPLOADING_ITEM = 'REMOVE_UPLOADING_ITEM';
export const SET_UPLOADING_TASKS = 'SET_UPLOADING_TASKS';
export const REMOVE_UPLOADING_TASK = 'REMOVE_UPLOADING_TASK';
export const SET_UPLOAD_OBSERVER = 'SET_UPLOAD_OBSERVER';
export const SET_UPLOAD_SESSION = 'SET_UPLOAD_SESSION';

export const resetUpload = () => ({ type: RESET_UPLOAD });

export const setUploadCount = (clientId: string, uploadCount: number) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadCount: number }>
) =>
  dispatch({
    type: SET_UPLOAD_COUNT,
    clientId,
    uploadCount
  });

export const setUploadedCount = (clientId: string, uploadedCount: number) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadedCount: number }>
) =>
  dispatch({
    type: SET_UPLOADED_COUNT,
    clientId,
    uploadedCount
  });

export const addToUploadedCount = (clientId: string) => (dispatch: Dispatch<{ type: string; clientId: string }>) =>
  dispatch({
    type: ADD_TO_UPLOADED_COUNT,
    clientId
  });

export const setUploadingItems = (clientId: string, uploadingItems: UploadingItemsType) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadingItems: UploadingItemsType }>
) =>
  dispatch({
    type: SET_UPLOADING_ITEMS,
    clientId,
    uploadingItems
  });

export const removeUploadingItems = (clientId: string) => (dispatch: Dispatch<{ type: string; clientId: string }>) =>
  dispatch({
    type: REMOVE_UPLOADING_ITEMS,
    clientId
  });

export const removeUploadingItem = (clientId: string, itemId: string) => (
  dispatch: Dispatch<{ type: string; clientId: string; itemId: string }>
) =>
  dispatch({
    type: REMOVE_UPLOADING_ITEM,
    clientId,
    itemId
  });

export const setUploadingTasks = (clientId: string, uploadingTasks: UploadingTasksType) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadingTasks: UploadingTasksType }>
) =>
  dispatch({
    type: SET_UPLOADING_TASKS,
    clientId,
    uploadingTasks
  });

export const removeUploadingTask = (clientId: string, taskId: string) => (
  dispatch: Dispatch<{ type: string; clientId: string; taskId: string }>
) =>
  dispatch({
    type: REMOVE_UPLOADING_TASK,
    clientId,
    taskId
  });

export const setUploadObserver = (clientId: string, uploadObserver: JoyMediumsObserver | null) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadObserver: JoyMediumsObserver | null }>
) =>
  dispatch({
    type: SET_UPLOAD_OBSERVER,
    clientId,
    uploadObserver
  });

export const setUploadSession = (clientId: string, uploadSession: string | null) => (
  dispatch: Dispatch<{ type: string; clientId: string; uploadSession: string | null }>
) =>
  dispatch({
    type: SET_UPLOAD_SESSION,
    clientId,
    uploadSession
  });
