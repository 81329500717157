import React, { Component } from 'react';
import classNames from 'classnames';

import Avatar from '@components/Avatar';
import Text from '@components/Text';
import Button from '@components/Button';
import SearchInput from '@components/SearchInput';
import LoadingLoop from '@components/LoadingLoop';
import { ClientItemType } from '@sections/MediaManager';
// import AddNewClient from './AddNewClient';
import styles from './ClientList.module.scss';
import UploadIcon from '@icons/UploadIcon';

interface ClientItemProps {
  client: ClientItemType;
  selected?: boolean;
  showUpload?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface PageProps {
  loading: boolean;
  clients: Array<ClientItemType>;
  uploadingClients: Array<string>;
  selectedEmail?: string | null;
  disableLoadMore?: boolean;
  onSearch: (search: string, loadMore?: boolean, resetCache?: boolean) => void;
  onSelect: (clientEmail: string | null, clientId: string | null) => void;
}

interface PageState {
  search: string;
  openAddNew: boolean;
  isBusy: boolean;
}

function ClientStatusDisplay({ status = '' }) {
  switch (status) {
    case 'active':
      return (
        <Text variant="bodySmall" color="secondary">
          Active
        </Text>
      );
    case 'inprogress':
      return (
        <Text variant="bodySmall" color="primary">
          In Progress
        </Text>
      );
    case 'completed':
      return (
        <Text variant="bodySmall" color="disabled">
          Completed
        </Text>
      );
    default:
      return null;
  }
}

function ClientItem({
  client: { name, email, status = '', dealId = '' },
  selected,
  showUpload,
  onClick
}: ClientItemProps) {
  return (
    <div
      className={classNames(styles.clientItem, { [styles.clientItemSelected]: selected })}
      data-value={dealId}
      data-email={email}
      onClick={onClick}
    >
      <Avatar name={name} />

      <div className={styles.clientItemInfo}>
        <Text variant="paragraph">{name}</Text>
        <Text variant="caption" color="muted">
          {email}
        </Text>
      </div>

      {showUpload && <UploadIcon color="muted" size={18} />}

      <ClientStatusDisplay status={status} />
    </div>
  );
}

class ClientList extends Component<PageProps, PageState> {
  private searchTimeout: number = 0;

  constructor(props: PageProps) {
    super(props);

    this.state = {
      search: '',
      openAddNew: false,
      isBusy: false
    };
  }

  handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState({ search: event.currentTarget.value }, () => {
      this.searchTimeout = window.setTimeout(() => {
        this.props.onSearch(this.state.search, false, true);
      }, 300);
    });
  };

  handleLoadMore = () => {
    this.props.onSearch(this.state.search, true);
  };

  handleClientSelect = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.onSelect(currentTarget.getAttribute('data-email'), currentTarget.getAttribute('data-value'));
  };

  openAddNew = () => {
    this.setState({ openAddNew: true });
  };

  closeAddNew = () => {
    this.setState({ openAddNew: false });
  };

  handleAddNew = () => {
    // TODO: reload clients after new client added
  };

  get clientList() {
    const {
      state: { search },
      props: { loading, clients, selectedEmail, uploadingClients }
    } = this;

    if (clients.length === 0) {
      if (loading) {
        return <LoadingLoop color="disabled" className={styles.clientListLoop} />;
      }

      if (search) {
        return (
          <Text className={styles.clientListMessage} variant="body" color="muted">
            No clients found for '{search}'.
          </Text>
        );
      }

      return (
        <Text className={styles.clientListMessage} variant="body" color="muted">
          No clients found.
        </Text>
      );
    }

    return clients.map((item: ClientItemType) => (
      <ClientItem
        key={`${item.email}_${item.dealId}`}
        client={item}
        selected={item.email === selectedEmail}
        onClick={this.handleClientSelect}
        showUpload={item.dealId ? uploadingClients.includes(item.dealId) : false}
      />
    ));
  }

  render() {
    const {
      state: { search /* openAddNew */ },
      props: { disableLoadMore, loading, clients }
    } = this;

    return (
      <div className={styles.wrapper}>
        <div className={styles.listActions}>
          <SearchInput onChange={this.handleSearchChange} value={search} />

          {/* <Button
            className={styles.addNewClient}
            variant="outlined"
            size="small"
            color="secondary"
            onClick={this.openAddNew}
          >
            Add New
          </Button> */}
        </div>

        <div className={styles.listTitle}>CLIENTS</div>

        <div className={styles.clientList}>
          {this.clientList}

          {disableLoadMore || (loading && clients.length === 0) || (
            <div className={styles.loadMoreButton}>
              <Button color="primary" onClick={this.handleLoadMore} isBusy={loading}>
                More...
              </Button>
            </div>
          )}
        </div>

        {/* <AddNewClient open={openAddNew} onClose={this.closeAddNew} onSubmit={this.handleAddNew} /> */}
      </div>
    );
  }
}

export default ClientList;
